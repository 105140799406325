// 大学共通テストの場合に使用する教科名のリスト
export const SUBJECT_NAMES_FOR_COMMON_TEST = {
  '1599990201': '数学IA',
  '1599990202': '数学I',
  '1599990203': '数学IIB',
  '1599990204': '数学II',
  '1699990201': '数学IA',
  '1699990202': '数学I',
  '1699990203': '数学IIB',
  '1699993004': '数学II',
  '1799990201': '数学IA',
  '1799990202': '数学I',
  '1799990203': '数学IIB',
  '1799990204': '数学II',
  '1899990201': '数学IA',
  '1899990202': '数学I',
  '1899990203': '数学IIB',
  '1899990204': '数学II',
  '1999990201': '数学IA',
  '1999990202': '数学I',
  '1999990203': '数学IIB',
  '1999990204': '数学II',
  '2099990201': '数学IA',
  '2099990202': '数学I',
  '2099990203': '数学IIB',
  '2099990204': '数学II',
  '2199990201': '数学IA',
  '2199990203': '数学IIB',
  '2299990201': '数学IA',
  '2299990203': '数学IIB',
  '1599990401': '物理',
  '1599990402': '物理基礎',
  '1699990401': '物理',
  '1699990402': '物理基礎',
  '1799990401': '物理',
  '1799990402': '物理基礎',
  '1899990401': '物理',
  '1899990402': '物理基礎',
  '1999990401': '物理',
  '1999990402': '物理基礎',
  '2099990401': '物理',
  '2099990402': '物理基礎',
  '2199990401': '物理',
  '2199990402': '物理基礎',
  '2299990401': '物理',
  '2299990402': '物理基礎',
  '1599990501': '化学',
  '1599990502': '化学基礎',
  '1699990501': '化学',
  '1699990502': '化学基礎',
  '1799990501': '化学',
  '1799990502': '化学基礎',
  '1899990501': '化学',
  '1899990502': '化学基礎',
  '1999990501': '化学',
  '1999990502': '化学基礎',
  '2099990501': '化学',
  '2099990502': '化学基礎',
  '2199990501': '化学',
  '2199990502': '化学基礎',
  '2299990501': '化学',
  '2299990502': '化学基礎',
  '1899990601': '生物',
  '1899990602': '生物基礎',
  '1999990601': '生物',
  '1999990602': '生物基礎',
  '2099990601': '生物',
  '2099990602': '生物基礎',
  '2199990601': '生物',
  '2199990602': '生物基礎',
  '2299990601': '生物',
  '2299990602': '生物基礎',
  '1799990702': '日本史B',
  '1899990702': '日本史B',
  '1999990702': '日本史B',
  '2099990702': '日本史B',
  '2199990702': '日本史B',
  '2299990702': '日本史B',
  '1599990802': '世界史B',
  '1699990802': '世界史B',
  '1799990802': '世界史B',
  '1899990802': '世界史B',
  '1999990802': '世界史B',
  '2099990802': '世界史B',
  '2199990802': '世界史B',
  '2199990804': '世界史B',
  '2299990802': '世界史B',
  '2399990201': '数学IA',
  '2399990202': '数学I',
  '2399990203': '数学IIB',
  '2399990204': '数学II',
  '2399990401': '物理',
  '2399990402': '物理基礎',
  '2399990501': '化学',
  '2399990502': '化学基礎',
  '2399990701': '日本史A',
  '2399990702': '日本史B',
  '2399990801': '世界史A',
  '2399990802': '世界史B',
  '2399990601': '生物',
  '2399990602': '生物基礎',
  '2099990902': '地理B',
  '2199990902': '地理B',
  '2299990902': '地理B',
  '2399990901': '地理A',
  '2399990902': '地理B',
  '2499990201': '数学IA',
  '2499990202': '数学I',
  '2499990203': '数学IIB',
  '2499990204': '数学II',
  '2499990401': '物理',
  '2499990402': '物理基礎',
  '2499990501': '化学',
  '2499990502': '化学基礎',
  '2499990701': '日本史A',
  '2499990702': '日本史B',
  '2499990801': '世界史A',
  '2499990802': '世界史B',
  '2499990601': '生物',
  '2499990602': '生物基礎',
  '2499990901': '地理A',
  '2499990902': '地理B'
};
