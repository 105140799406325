import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { getPreviousProblemId, getNextProblemId } from 'src/app/selectors/search.selectors';
import { setBrowserTitle, navigate } from 'src/app/actions/core.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { ReadablePlaylistProblem } from 'src/app/models/problem';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';
import { getNextBookmarkProblemId, getPreviousBookmarkProblemId } from 'src/app/selectors/bookmark.selectors';

@Component({
  selector: 'app-problem-detail-back-forward-button',
  templateUrl: './problem-detail-back-forward-button.component.html',
  styleUrls: ['./problem-detail-back-forward-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProblemDetailBackForwardButtonComponent implements OnInit {
  @Input() isFirstProblem: boolean;
  @Input() isLastProblem: boolean;
  @Input() navigation: boolean;
  @Input() parentComponent: string;
  @Input() readableSortType: string;
  @Input() problemId: string;
  @Input() playlistProblems: ReadablePlaylistProblem[];
  @Input() themeId: string;
  @Input() playlistId: string;
  @Output() menuTypeChangeHandler = new EventEmitter();
  @Output() setLoadingReadableProblems = new EventEmitter();

  toolbarTitle = '問題詳細・前へ次へボタン';
  SEARCH_PROBLEM_DETAIL: string;
  BOOKMARK_PROBLEM_DETAIL: string;
  PLAYLIST_PROBLEM_DETAIL: string;

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, @Inject(WINDOW_OBJECT) private window: Window) {
    this.SEARCH_PROBLEM_DETAIL = 'searchProblemDetail';
    this.BOOKMARK_PROBLEM_DETAIL = 'bookmarkProblemDetail';
    this.PLAYLIST_PROBLEM_DETAIL = 'playlistProblemDetail';
  }

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.toolbarTitle }));
  }

  showPreviousProblem(parentComponent: string) {
    this.changeMenuType('article');

    switch (parentComponent) {
      case this.SEARCH_PROBLEM_DETAIL: {
        this.store.select(getPreviousProblemId(this.problemId)).subscribe(prevProblemId => {
          this.goToProblemDetail(prevProblemId);
        });
        break;
      }
      case this.BOOKMARK_PROBLEM_DETAIL: {
        this.store.select(getPreviousBookmarkProblemId(this.problemId)).subscribe(prevProblemId => {
          this.goToProblemDetail(prevProblemId);
        });
        break;
      }

      default: {
        this.setLoadingReadableProblems.emit(true);
        const prevProblem = this.playlistProblems[
          this.playlistProblems.findIndex(problem => problem.themeId === this.themeId && problem.id === this.problemId) - 1
        ];
        this.goToPlaylistProblemDetail(prevProblem);
      }
    }
  }

  showNextProblem(parentComponent: string) {
    this.changeMenuType('article');
    switch (parentComponent) {
      case this.SEARCH_PROBLEM_DETAIL: {
        this.store.select(getNextProblemId(this.problemId)).subscribe(nextProblemId => {
          this.goToProblemDetail(nextProblemId);
        });
        break;
      }
      case this.BOOKMARK_PROBLEM_DETAIL: {
        this.store.select(getNextBookmarkProblemId(this.problemId)).subscribe(nextProblemId => {
          this.goToProblemDetail(nextProblemId);
        });
        break;
      }
      default: {
        this.setLoadingReadableProblems.emit(true);
        // 国語の場合、problemsに同一problemIdの問題が存在するため逆順で検索を行う
        const nextProblemIndex = this.playlistProblems
          .slice()
          .reverse()
          .findIndex(problem => problem.themeId === this.themeId && problem.id === this.problemId);
        const nextProblem = this.playlistProblems[this.playlistProblems.length - nextProblemIndex];
        this.goToPlaylistProblemDetail(nextProblem);
      }
    }
  }

  closeWindow() {
    this.window.close();
  }

  changeMenuType(type: string) {
    this.menuTypeChangeHandler.emit(type);
  }

  private goToProblemDetail(problemId: string) {
    const { queryParams } = this.activatedRoute.snapshot;
    const url = RoutingPathResolver.resolveProblemDetail(problemId);
    this.store.dispatch(navigate({ url, extras: { queryParams } }));
  }

  private goToPlaylistProblemDetail(problem: ReadablePlaylistProblem) {
    const url = RoutingPathResolver.resolvePlaylistProblemDetail(this.playlistId, problem.themeId, problem.id);
    this.store.dispatch(navigate({ url }));
  }
}
