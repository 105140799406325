import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { KakomonImages } from '../models/kakomon-image-search';
import { CallableFunction } from '../resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class KakomonImageSearchService {
  constructor(private aff: AngularFireFunctions) {}

  findProblemImages(problemId: string): Observable<KakomonImages> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_IMAGES);
    return callable({ problemId });
  }
}
