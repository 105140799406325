import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { navigate, openWindow } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { DOCUMENT } from '@angular/common';
import { NEW_INSTALLATION_URL } from '../../../resources/config';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  newInstallationUrl: string = NEW_INSTALLATION_URL;

  private threshold = 300; // スクロール量がこの値を超えたらヘッダーを表示する
  headerIsShow = false;

  constructor(private store: Store<RootState>, @Inject(DOCUMENT) private document: Document) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    const currentPos: number = e.target.scrollingElement.scrollTop;
    if (currentPos > this.threshold) {
      this.headerIsShow = true;
    } else {
      this.headerIsShow = false;
    }
  }

  ngOnInit() {}

  newInstallationUrlClickHandler() {
    this.store.dispatch(openWindow({ url: this.newInstallationUrl }));
  }

  gotoSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
  }
}
