import { createAction, props } from '@ngrx/store';
import { KakomonImages } from '../models/kakomon-image-search';

export const findProblemImages = createAction('[KakomonImageSearch] Find Problem Images', props<{ problemId: string }>());

export const findProblemImagesSuccess = createAction(
  '[KakomonImageSearch] Find Problem Images Success',
  props<{ kakomonImages: KakomonImages }>()
);

export const findProblemImagesFailure = createAction('[KakomonImageSearch] Find Problem Images Failure');
