<ng-template
  #cardTemplate
  let-highlight="highlight"
  let-title="title"
  let-plan="plan"
  let-price="price"
  let-period="period"
  let-duration="duration"
  let-badge="badge"
  let-buttonText="buttonText"
  let-buttonIsShow="buttonIsShow"
  let-buttonDisabled="buttonDisabled"
  let-monthlyPrice="monthlyPrice"
  let-planId="planId"
>
  <div
    class="text-md leading-4 w-full shrink bg-white rounded-2xl p-9 shadow-black-whisper relative border-2 border-border-line"
    [class.highlight-glow]="highlight"
  >
    <div class="flex flex-col items-center gap-6 mx-auto max-w-72 md:max-w-screen-xs">
      <div class="tracking-widest font-bold text-yellow-gold-darker whitespace-nowrap">{{ title }}</div>
      <div class="font-bold text-base">
        <span *ngIf="plan === 3" class="mr-1">短期集中</span>
        <span class="text-[1.5em] -mr-[0.1em]">{{ plan }}</span>
        ヶ月プラン
      </div>
      <div class="font-bold text-3xl -mr-4">
        <strong class="tracking-wide -mr-1">¥ {{ price }}</strong>
        <span class="text-xs ml-2">(税込)</span>
      </div>
      <div
        class="bg-white border border-border-line rounded-lg w-full divide-border-line divide-y text-sm [&>div]:min-h-8 [&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:px-2 tracking-widest"
      >
        <div *ngIf="period">
          <div class="font-bold mx-auto">{{ period }}</div>
        </div>
        <div *ngIf="monthlyPrice">
          <div class="text-xs text-black/50">ひと月あたり</div>
          <div>
            <span class="font-bold text-base">￥{{ monthlyPrice }}</span>
            <span class="text-xs tracking-normal">(税込)</span>
          </div>
        </div>
        <div>
          <div class="text-xs text-black/50">サービス利用開始から</div>
          <div>{{ duration }}</div>
        </div>
      </div>
      <button
        *ngIf="buttonIsShow"
        class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg"
        mat-flat-button
        color="primary"
        (click)="gotoStripeClick.emit({ planId })"
        [disabled]="buttonDisabled"
      >
        {{ buttonText }}
      </button>
      <div
        *ngIf="badge"
        class="whitespace-pre-line text-white absolute -top-6 -right-6 size-16 rounded-full flex items-center justify-center font-bold tracking-widest leading-4"
        [ngClass]="{
          'bg-primary': badge === '期間\n限定',
          'bg-yellow-gold-dark': badge === 'おトク!'
        }"
      >
        {{ badge }}
      </div>
    </div>
  </div>
</ng-template>

<!-- ***************************************************** -->

<div class="flex flex-col lg:flex-row gap-6 lg:gap-8">
  <!-- 3ヶ月プランのカード -->
  <ng-container
    *ngTemplateOutlet="
      cardTemplate;
      context: {
        highlight: true,
        title: '＼ 入試直前に! ／',
        plan: 3,
        price: '3,500',
        period: '25年3月26日までの限定販売！',
        duration: '92日間',
        badge: '期間\n限定',
        buttonText: '購入画面へ',
        buttonIsShow: buttonIsShow,
        buttonDisabled: buttonDisabled,
        monthlyPrice: null,
        planId: shortTerm3MonthsPlan.id
      }
    "
  ></ng-container>

  <!-- 6ヶ月プランのカード -->
  <ng-container
    *ngTemplateOutlet="
      cardTemplate;
      context: {
        highlight: false,
        title: '＼ じっくり準備したい方に ／',
        plan: 6,
        price: '5,500',
        period: null,
        duration: '183日間',
        badge: null,
        buttonText: '購入画面へ',
        buttonIsShow: buttonIsShow,
        buttonDisabled: buttonDisabled,
        monthlyPrice: '917',
        planId: biannualPlan.id
      }
    "
  ></ng-container>

  <!-- 12ヶ月プランのカード -->
  <ng-container
    *ngTemplateOutlet="
      cardTemplate;
      context: {
        highlight: false,
        title: '＼ 1年を通して学習したい方に ／',
        plan: 12,
        price: '7,700',
        period: null,
        duration: '365日間',
        badge: 'おトク!',
        buttonText: '購入画面へ',
        buttonIsShow: buttonIsShow,
        buttonDisabled: buttonDisabled,
        monthlyPrice: '642',
        planId: annualPlan.id
      }
    "
  ></ng-container>
</div>

<!-- ***************************************************** -->

<!--<div class="flex flex-col lg:flex-row gap-6 lg:gap-8" *ngIf="parentComponent === ''">-->
<!--  &lt;!&ndash; 6ヶ月プランのカード &ndash;&gt;-->
<!--  <ng-container *ngTemplateOutlet="cardTemplate; context: {-->
<!--    highlight: true,-->
<!--    title: '＼ じっくり準備したい方に ／',-->
<!--    plan: 6,-->
<!--    price: '5,500',-->
<!--    period: null,-->
<!--    duration: '183日間',-->
<!--    badge: null,-->
<!--    buttonText: '購入画面へ',-->
<!--    buttonIsShow: true,-->
<!--    buttonDisabled: true,-->
<!--    monthlyPrice: '917'-->
<!--  }"></ng-container>-->

<!--  &lt;!&ndash; 12ヶ月プランのカード &ndash;&gt;-->
<!--  <ng-container *ngTemplateOutlet="cardTemplate; context: {-->
<!--    highlight: true,-->
<!--    title: '＼ 1年を通して学習したい方に ／',-->
<!--    plan: 12,-->
<!--    price: '7,700',-->
<!--    period: null,-->
<!--    duration: '365日間',-->
<!--    badge: 'おトク!',-->
<!--    buttonText: '購入画面へ',-->
<!--    buttonIsShow: true,-->
<!--    buttonDisabled: true,-->
<!--    monthlyPrice: '642'-->
<!--  }"></ng-container>-->
<!--</div>-->

<!--&lt;!&ndash; ***************************************************** &ndash;&gt;-->

<!--<div class="flex flex-col lg:flex-row gap-6 lg:gap-8" *ngIf="parentComponent === 'top'">-->
<!--  &lt;!&ndash; 3ヶ月プランのカード &ndash;&gt;-->
<!--  <ng-container *ngTemplateOutlet="cardTemplate; context: {-->
<!--    highlight: true,-->
<!--    title: '＼ 入試直前に! ／',-->
<!--    plan: 3,-->
<!--    price: '3,500',-->
<!--    period: '25年3月26日までの限定販売！',-->
<!--    duration: '92日間',-->
<!--    badge: '期間\n限定',-->
<!--    buttonText: '◯月◯日より販売開始!!',-->
<!--    buttonIsShow: false,-->
<!--    buttonDisabled: true,-->
<!--    monthlyPrice: null-->
<!--  }"></ng-container>-->

<!--  &lt;!&ndash; 6ヶ月プランのカード &ndash;&gt;-->
<!--  <ng-container *ngTemplateOutlet="cardTemplate; context: {-->
<!--    highlight: false,-->
<!--    title: '＼ じっくり準備したい方に ／',-->
<!--    plan: 6,-->
<!--    price: '5,500',-->
<!--    period: null,-->
<!--    duration: '183日間',-->
<!--    badge: null,-->
<!--    buttonText: '購入画面へ',-->
<!--    buttonIsShow: false,-->
<!--    buttonDisabled: false,-->
<!--    monthlyPrice: '917'-->
<!--  }"></ng-container>-->

<!--  &lt;!&ndash; 12ヶ月プランのカード &ndash;&gt;-->
<!--  <ng-container *ngTemplateOutlet="cardTemplate; context: {-->
<!--    highlight: false,-->
<!--    title: '＼ 1年を通して学習したい方に ／',-->
<!--    plan: 12,-->
<!--    price: '7,700',-->
<!--    period: null,-->
<!--    duration: '365日間',-->
<!--    badge: 'おトク!',-->
<!--    buttonText: '購入画面へ',-->
<!--    buttonIsShow: false,-->
<!--    buttonDisabled: false,-->
<!--    monthlyPrice: '642'-->
<!--  }"></ng-container>-->
<!--</div>-->

<!-- TODO: 不要になったら削除
<div class="flex flex-col lg:flex-row gap-6 lg:gap-8">
  <div class="w-full shrink bg-white rounded-2xl p-9 shadow-black-whisper relative border-2 border-border-line" [class.highlight-glow]="true">
    <div class="flex flex-col items-center gap-4 mx-auto max-w-72 md:max-w-screen-xs">
      <div class="tracking-widest font-bold text-yellow-gold-darker whitespace-nowrap">＼ 入試直前に! ／</div>
      <div class="font-bold text-base">
        短期集中
        <span class="text-[1.5em] -mr-[0.1em]">3</span>
        ヶ月プラン
      </div>
      <div class="font-bold text-3xl -mr-4">
        <strong class="tracking-wide -mr-1">¥ 3,500</strong>
        <span class="text-xs ml-2">(税込)</span>
      </div>
      <div class="bg-white border border-border-line rounded-lg w-full divide-border-line divide-y text-sm [&>div]:min-h-8 [&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:px-2 tracking-widest">
        <div>
          <div class="font-bold mx-auto">25年3月26日までの限定販売！</div>
        </div>
        <div>
          <div class="text-xs text-black/50">サービス利用開始から</div>
          <div>92日間</div>
        </div>
      </div>
      <button class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg" mat-flat-button color="primary">
        購入画面へ
      </button>
      <button class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg" mat-flat-button color="primary" disabled>
        ◯月◯日より販売開始!!
      </button>
      <div class="absolute -top-6 -right-6 bg-primary text-white size-16 rounded-full flex items-center justify-center font-bold tracking-wider">
        期間<br />
        限定
      </div>
    </div>
  </div>
  <div class="w-full shrink bg-white rounded-2xl p-9 shadow-black-whisper relative border-2 border-border-line" [class.highlight-glow]="false">
    <div class="flex flex-col items-center gap-4 mx-auto max-w-screen-xs">
      <div class="tracking-widest font-bold text-yellow-gold-darker whitespace-nowrap">＼ じっくり準備したい方に ／</div>
      <div class="font-bold text-base">
        <span class="text-[1.5em] -mr-[0.1em]">6</span>
        ヶ月プラン
      </div>
      <div class="font-bold text-3xl -mr-4">
        <strong class="tracking-wide -mr-1">¥ 5,500</strong>
        <span class="text-xs ml-2">(税込)</span>
      </div>
      <div class="bg-white border border-border-line rounded-lg w-full divide-border-line divide-y text-sm [&>div]:min-h-8 [&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:px-2 tracking-widest">
        <div>
          <div class="text-xs text-black/50">ひと月あたり</div>
          <div>
            <span class="font-bold text-base">￥917</span>
            <span class="text-xs tracking-normal">(税込)</span>
          </div>
        </div>
        <div>
          <div class="text-xs text-black/50">サービス利用開始から</div>
          <div>183日間</div>
        </div>
      </div>
      <button class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg" mat-flat-button color="primary">
        購入画面へ
      </button>
      <button class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg" mat-flat-button color="primary" disabled>
        購入画面へ
      </button>
    </div>
  </div>
  <div class="w-full shrink bg-white rounded-2xl p-9 shadow-black-whisper relative border-2 border-border-line" [class.highlight-glow]="false">
    <div class="flex flex-col items-center gap-4 mx-auto max-w-screen-xs">
      <div class="tracking-widest font-bold text-yellow-gold-darker whitespace-nowrap">＼ 1年を通して学習したい方に ／</div>
      <div class="font-bold text-base">
        <span class="text-[1.5em] -mr-[0.1em]">12</span>
        ヶ月プラン
      </div>
      <div class="font-bold text-3xl -mr-4">
        <strong class="tracking-wide -mr-1">¥ 7,700</strong>
        <span class="text-xs ml-2">(税込)</span>
      </div>
      <div class="bg-white border border-border-line rounded-lg w-full divide-border-line divide-y text-sm [&>div]:min-h-8 [&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:px-2 tracking-widest">
        <div>
          <div class="text-xs text-black/50">ひと月あたり</div>
          <div>
            <span class="font-bold text-base">￥642</span>
            <span class="text-xs tracking-normal">(税込)</span>
          </div>
        </div>
        <div>
          <div class="text-xs text-black/50">サービス利用開始から</div>
          <div>365日間</div>
        </div>
      </div>
      <button class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg" mat-flat-button color="primary">
        購入画面へ
      </button>
      <button class="bg-yellow-gold-dark md:h-12 w-full tracking-widest rounded-lg" mat-flat-button color="primary" disabled>
        購入画面へ
      </button>
      <div class="absolute -top-6 -right-6 bg-yellow-gold-dark text-white size-16 rounded-full flex items-center justify-center font-bold tracking-wider">
        おトク!
      </div>
    </div>
  </div>
</div>
-->
