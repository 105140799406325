import { Action, createReducer, on } from '@ngrx/store';
import * as KakomonImageSearchActions from '../actions/kakomon-image-search.actions';
import { KakomonImages } from '../models/kakomon-image-search';

export const kakomonImageSearchFeatureKey = 'kakomonImageSearch';

export interface State {
  kakomonImages: KakomonImages;
}

export const initialState: State = {
  kakomonImages: null
};

export const kakomonImageSearchReducer = createReducer(
  initialState,
  on(KakomonImageSearchActions.findProblemImagesSuccess, (state, { kakomonImages }) => ({
    ...state,
    kakomonImages
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return kakomonImageSearchReducer(state, action);
}
