import { createFeatureSelector, createSelector } from '@ngrx/store';
import { bookmarkFeatureKey, State } from '../reducers/bookmark.reducer';
import {
  getStaticCommonData,
  getStaticEnglishData,
  getStaticMathData,
  getStaticNationalLanguageData,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from './static-data.selectors';
import {
  translateEnglishProblems,
  translateHistoryProblems,
  translateNationalLanguageProblems,
  translateScienceProblems
} from './search.selectors';

const selectBookmarkState = createFeatureSelector<State>(bookmarkFeatureKey);

export const getBookmark = createSelector(selectBookmarkState, state => state.bookmark);
export const getAddBookmarkResult = createSelector(selectBookmarkState, state => state.addBookmarkResult);
export const getDeleteBookmarkResult = createSelector(selectBookmarkState, state => state.deleteBookmarkResult);

export const getBookmarkCount = createSelector(selectBookmarkState, state => state.bookmarkCount);

export const getBookmarkSearching = createSelector(selectBookmarkState, state => state.bookmarkSearching);

export const getEnglishBookmarkProblems = createSelector(selectBookmarkState, state => state.englishBookmarkProblems);
export const getMathBookmarkProblems = createSelector(selectBookmarkState, state => state.mathBookmarkProblems);
export const getNationalLanguageBookmarkProblems = createSelector(selectBookmarkState, state => state.nationalLanguageBookmarkProblems);
export const getPhysicsBookmarkProblems = createSelector(selectBookmarkState, state => state.physicsBookmarkProblems);
export const getChemistryBookmarkProblems = createSelector(selectBookmarkState, state => state.chemistryBookmarkProblems);
export const getBiologyBookmarkProblems = createSelector(selectBookmarkState, state => state.biologyBookmarkProblems);
export const getJapaneseHistoryBookmarkProblems = createSelector(selectBookmarkState, state => state.japaneseHistoryBookmarkProblems);
export const getWorldHistoryBookmarkProblems = createSelector(selectBookmarkState, state => state.worldHistoryBookmarkProblems);
export const getGeographyBookmarkProblems = createSelector(selectBookmarkState, state => state.geographyBookmarkProblems);
export const getPoliticalEconomyBookmarkProblems = createSelector(selectBookmarkState, state => state.politicalEconomyBookmarkProblems);

export const getMatchedBookmarkProblemIds = createSelector(selectBookmarkState, state => state.matchedBookmarkProblemIds);
export const getBookmarkProblemIdsSearching = createSelector(selectBookmarkState, state => state.isBookmarkProblemIdsSearching);

export const getReadableEnglishBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticEnglishData,
  getEnglishBookmarkProblems,
  (staticCommonData, staticEnglishData, englishProblems) => translateEnglishProblems(staticCommonData, staticEnglishData, englishProblems)
);

export const getReadableMathBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticMathData,
  getMathBookmarkProblems,
  (staticCommonData, staticMathData, mathProblems) => translateScienceProblems(staticCommonData, staticMathData, mathProblems)
);

export const getReadableNationalLanguageBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticNationalLanguageData,
  getNationalLanguageBookmarkProblems,
  (staticCommonData, staticNationalLanguageData, nationalLanguageProblems) =>
    translateNationalLanguageProblems(staticCommonData, staticNationalLanguageData, nationalLanguageProblems)
);

export const getReadablePhysicsBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticPhysicsData,
  getPhysicsBookmarkProblems,
  (staticCommonData, staticPhysicsData, physicsProblems) => translateScienceProblems(staticCommonData, staticPhysicsData, physicsProblems)
);

export const getReadableChemistryBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticChemistryData,
  getChemistryBookmarkProblems,
  (staticCommonData, staticChemistryData, chemistryProblems) =>
    translateScienceProblems(staticCommonData, staticChemistryData, chemistryProblems)
);

export const getReadableBiologyBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticBiologyData,
  getBiologyBookmarkProblems,
  (staticCommonData, staticBiologyData, biologyProblems) => translateScienceProblems(staticCommonData, staticBiologyData, biologyProblems)
);

export const getReadableJapaneseHistoryBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticJapaneseHistoryData,
  getJapaneseHistoryBookmarkProblems,
  (staticCommonData, staticJapaneseHistoryData, japaneseHistoryProblems) =>
    translateHistoryProblems(staticCommonData, staticJapaneseHistoryData, japaneseHistoryProblems)
);

export const getReadableWorldHistoryBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticWorldHistoryData,
  getWorldHistoryBookmarkProblems,
  (staticCommonData, staticWorldHistoryData, worldHistoryProblems) =>
    translateHistoryProblems(staticCommonData, staticWorldHistoryData, worldHistoryProblems)
);

export const getReadableGeographyBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticGeographyData,
  getGeographyBookmarkProblems,
  (staticCommonData, staticGeographyData, geographyProblems) =>
    translateScienceProblems(staticCommonData, staticGeographyData, geographyProblems)
);

export const getReadablePoliticalEconomyBookmarkProblems = createSelector(
  getStaticCommonData,
  getStaticPoliticalEconomyData,
  getPoliticalEconomyBookmarkProblems,
  (staticCommonData, staticPoliticalEconomyData, politicalEconomyProblems) =>
    translateScienceProblems(staticCommonData, staticPoliticalEconomyData, politicalEconomyProblems)
);

export const getFirstProblemIdFromMatchedBookmarkProblemIds = createSelector(getMatchedBookmarkProblemIds, (problemIds: string[] | null):
  | string
  | null => {
  if (problemIds == null) return null;
  return [...problemIds].shift() || '';
});

export const getLastProblemIdFromMatchedBookmarkProblemIds = createSelector(getMatchedBookmarkProblemIds, (problemIds: string[] | null):
  | string
  | null => {
  if (problemIds == null) return null;
  return [...problemIds].pop() || '';
});

export const getPreviousBookmarkProblemId = (currentProblemId: string) =>
  createSelector(getMatchedBookmarkProblemIds, (problemIds: string[] | null): string | null => {
    if (problemIds == null) return null;
    const prevIdIndex = problemIds.findIndex(it => it === currentProblemId) - 1;
    if (prevIdIndex < 0) return '';
    return problemIds[prevIdIndex];
  });

export const getNextBookmarkProblemId = (currentProblemId: string) =>
  createSelector(getMatchedBookmarkProblemIds, (problemIds: string[] | null): string | null => {
    if (problemIds == null) return null;
    const nextIdIndex = problemIds.findIndex(it => it === currentProblemId) + 1;
    if (nextIdIndex >= problemIds.length) return '';
    return problemIds[nextIdIndex];
  });
