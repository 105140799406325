import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

import { AuthEffects } from './effects/auth.effects';
import { BookmarkEffects } from './effects/bookmark.effects';
import { BulkMailEffects } from './effects/bulk-mail.effects';
import { CommonIdAuthEffects } from './effects/common-id/common-id-auth.effects';
import { CommonIdCoreEffects } from './effects/common-id/common-id-core.effects';
import { CommonIdEventLogEffects } from './effects/common-id/common-id-event-log.effects';
import { CommonIdFindUniversityEffects } from './effects/common-id/common-id-find-university.effects';
import { CommonIdGetCustomTokenEffects } from './effects/common-id/common-id-get-custom-token.effects';
import { CommonIdGetStripeCheckoutUrlEffects } from './effects/common-id/common-id-get-stripe-checkout-url.effects';
import { CommonIdInformationEffects } from './effects/common-id/common-id-information.effects';
import { CommonIdPaperBookmarkEffects } from './effects/common-id/common-id-paper-bookmark.effects';
import { CommonIdSearchEffects } from './effects/common-id/common-id-search.effects';
import { CommonIdUniversityBookmarkEffects } from './effects/common-id/common-id-university-bookmark.effects';
import { CommonIdUserEffects } from './effects/common-id/common-id-user.effects';
import { CommonIdPlaylistEffects } from './effects/common-id/common-id-playlist.effects';
import { CommonIdPlaylistBookmarkEffects } from './effects/common-id/common-id-playlist-bookmark.effects';
import { CommonIdAnsweredProblemEffects } from './effects/common-id/common-id-answered-problem.effects';
import { CommonIdBookmarkAndAnsweredPaperEffects } from './effects/common-id/common-id-bookmark-and-answered-paper.effects';
import { CommonIdPurchasesEffects } from './effects/common-id/common-id-purchases.effects';
import { UniversitySearchEffects } from './effects/university-search.effects';
import { CoreEffects } from './effects/core.effects';
import { CurrentDateTimeEffects } from './effects/current-date-time.effects';
import { EventLogEffects } from './effects/event-log.effects';
import { JukuEffects } from './effects/juku.effects';
import { MaintenanceEffects } from './effects/maintenance.effects';
import { PlanEffects } from './effects/plan.effects';
import { PlaylistEffects } from './effects/playlist.effects';
import { SearchEffects } from './effects/search.effects';
import { StaticDataEffects } from './effects/static-data.effects';
import { ToolsEffects } from './effects/tools.effects';
import { TrySearchEffects } from './effects/try-search.effects';
import { UserEffects } from './effects/user.effects';
import { metaReducers, reducers } from './reducers';
import { FindUniversityEffects } from './effects/find-university.effects';
import { CommonIdFindPlaylistOriginalProblemIdsEffects } from './effects/common-id/common-id-find-playlist-original-problems.effects';
import { InformationEffects } from './effects/information.effects';
import { KakomonImageSearchEffects } from './effects/kakomon-image-search.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([
      AuthEffects,
      CoreEffects,
      CurrentDateTimeEffects,
      StaticDataEffects,
      SearchEffects,
      PlanEffects,
      PlaylistEffects,
      UserEffects,
      EventLogEffects,
      ToolsEffects,
      TrySearchEffects,
      BookmarkEffects,
      JukuEffects,
      BulkMailEffects,
      MaintenanceEffects,
      CommonIdCoreEffects,
      CommonIdAuthEffects,
      CommonIdGetCustomTokenEffects,
      CommonIdSearchEffects,
      CommonIdUniversityBookmarkEffects,
      CommonIdPaperBookmarkEffects,
      CommonIdFindUniversityEffects,
      CommonIdInformationEffects,
      CommonIdEventLogEffects,
      CommonIdGetStripeCheckoutUrlEffects,
      CommonIdUserEffects,
      CommonIdPlaylistEffects,
      CommonIdPlaylistBookmarkEffects,
      CommonIdAnsweredProblemEffects,
      CommonIdBookmarkAndAnsweredPaperEffects,
      CommonIdPurchasesEffects,
      UniversitySearchEffects,
      FindUniversityEffects,
      CommonIdFindPlaylistOriginalProblemIdsEffects,
      InformationEffects,
      KakomonImageSearchEffects
    ])
  ]
})
export class AppStoreModule {
  constructor(@Optional() @SkipSelf() parentModule: AppStoreModule) {
    if (parentModule) {
      throw new Error('AppStoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
