<div class="flex items-center justify-start sm:justify-center w-full overflow-auto" *ngIf="navigation">
  <div class="hidden sm:block" style="width: 100px;">
    <!-- spacer -->
  </div>
  <div class="mr-auto sm:mx-auto flex items-center">
    <button
      mat-flat-button
      class="text-xs md:text-md"
      color="primary"
      [disabled]="isFirstProblem"
      (click)="showPreviousProblem(parentComponent)"
    >
      <mat-icon>arrow_back</mat-icon>
      前へ
    </button>
    <div
      class="mx-4 text-xs sm:text-md text-sub text-center whitespace-nowrap"
      *ngIf="parentComponent === SEARCH_PROBLEM_DETAIL || parentComponent === BOOKMARK_PROBLEM_DETAIL"
    >
      <span class="block sm:inline-block"
        >並び替え
        <span class="hidden sm:inline-block mr-2">:</span>
      </span>
      <span class="block sm:inline-block">{{ this.readableSortType }}</span>
    </div>
    <div class="mx-2" *ngIf="parentComponent === PLAYLIST_PROBLEM_DETAIL"></div>
    <button
      mat-flat-button
      class="text-xs md:text-md"
      color="primary"
      [disabled]="isLastProblem"
      (click)="showNextProblem(parentComponent)"
    >
      次へ
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
  <button mat-flat-button class="bg-white-alpha-50 text-xs md:text-md text-gray-500 ml-2" (click)="closeWindow()">
    <mat-icon>highlight_off</mat-icon>
    閉じる
  </button>
</div>

<div class="flex items-center" style="justify-content: right;" *ngIf="!navigation">
  <button mat-flat-button class="ml-auto bg-white-alpha-50 text-xs md:text-md text-gray-500" (click)="closeWindow()">
    <mat-icon>highlight_off</mat-icon>
    閉じる
  </button>
</div>
