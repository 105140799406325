import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-content-lp-functional-comparison',
  templateUrl: './content-lp-functional-comparison.component.html',
  styleUrls: ['./content-lp-functional-comparison.component.scss']
})
export class ContentLpFunctionalComparisonComponent implements OnInit {
  @Output() gotoFaqClick = new EventEmitter<{ faqId: string }>();

  constructor() {}
  ngOnInit() {}
}
