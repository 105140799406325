<section class="section-price">
  <h2 class="text-center mb-8 md:mb-12 text-2xl md:text-3xl text-black font-semibold tracking-widest">機能比較</h2>
  <table class="table-price">
    <thead>
      <tr>
        <th class="w-4/12 transparent"></th>
        <th class="w-4/12" data-cell-type="free">無料会員</th>
        <th class="w-4/12" data-cell-type="premium">
          <div class="flex items-center justify-center gap-2 md:gap-4 mx-auto -mt-1 md:-mt-3">
            <div
              class="shadow-premium-darker size-6 md:size-10 bg-white rounded-md md:rounded-lg flex items-center justify-center shrink-0"
            >
              <app-icon-premium class="text-yellow-gold text-md md:text-xl leading-none"></app-icon-premium>
            </div>
            <span class="text-left leading-4 md:leading-6">入試正解<br class="sm:hidden" />デジタル<br />プレミアム</span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>
          過去問閲覧<br />
          <small class="text-xs md:text-sm inline-block leading-snug">（大学から探す・分野から探す）</small>
        </th>
        <td data-cell-type="free" class="tracking-wide">2024・2023<wbr />年度<wbr />入試<sup>※3</sup></td>
        <td data-cell-type="premium">無制限<sup>※1</sup></td>
      </tr>
      <tr>
        <th>おすすめ問題<br class="md:hidden" />セット</th>
        <td class="" data-cell-type="free">
          お試しのみ
        </td>
        <td class="" data-cell-type="premium">
          無制限
        </td>
      </tr>
      <tr>
        <th>
          共通テスト対策<br />
          予想問題セット<sup class="text-xs" style="top: -0.2em;">※2</sup>
        </th>
        <td class="" data-cell-type="free">
          -
        </td>
        <td class="" data-cell-type="premium">
          無制限
        </td>
      </tr>
      <tr>
        <th>
          マイリスト
        </th>
        <td data-cell-type="free">
          <img srcset="/assets/images/lp/student/check-silver.svg" alt="✓" />
        </td>
        <td data-cell-type="premium">
          <img srcset="/assets/images/lp/student/check-gold.svg" alt="✓" />
        </td>
      </tr>
    </tbody>
  </table>
  <div class="mt-2 md:mt-4">
    <div class="text-sm leading-6 flex flex-col md:flex-row gap-2 md:gap-6">
      <p class="grow w-full">
        ※1【対応科目と掲載年度】<br />
        数学、物理、化学：2015年度～2024年度<br />
        生物：2018年度～2024年度<br />
        日本史、世界史：2019年度～2024年度<br />
        地理、政治・経済：2020年度～2024年度<br />
        英語、国語：2020年度～2024年度（2020年度の問題は、2025年3月下旬に掲載を終了します）<br />
      </p>
      <p class="grow w-full">
        年度についての詳細は<button class="text-primary underline hover:no-underline" (click)="gotoFaqClick.emit({ faqId: 'faq-10' })">
          こちらのFAQ</button
        >をご覧ください。<br />
        <br />
        著作権等の関係で、一部閲覧できない問題もございます。<br />
        2025年度入試は、2025年12月頃に搭載予定です。<br />
        ※2 共通テスト対策予想問題セットはおすすめ問題セットの中で掲載しています。<br />
        ※3 2023年度入試は、2025年3月下旬まで閲覧可能です<br />
      </p>
    </div>
  </div>
</section>

<!-- ********************************** -->

<section class="mt-8 mb-8">
  <div class="bg-yellow-gold/20 rounded-xl sm:mb-10">
    <h3 class="text-center py-8 mb-0 text-lg md:text-xl text-black font-semibold tracking-widest">＼こんなあなたにおすすめ!!／</h3>
    <div>
      <ng-template #speachTemplate let-body="body" let-imageSrc="imageSrc">
        <div class="flex flex-row sm:flex-col gap-4 sm:gap-6 justify-center items-center sm:items-stretch w-full">
          <div
            class="grow shadow-premium-darker relative p-3 sm:py-6 rounded-xl bg-white text-left text-base font-normal leading-relaxed text-black before:absolute before:top-1/2 before:right-0 before:border-y-8 before:border-l-8 before:border-y-transparent before:border-l-white before:translate-x-full before:-translate-y-1/2 sm:before:top-auto sm:before:bottom-0 sm:before:right-1/2 sm:before:border-x-8 sm:before:border-t-8 sm:before:border-b-0 sm:before:border-x-transparent sm:before:border-t-white sm:before:translate-x-1/2 sm:before:translate-y-full"
          >
            <div class="whitespace-pre-line font-bold text-sm sm:text-md text-center">
              {{ body }}
            </div>
          </div>
          <div
            class="mx-auto shrink-0 rounded-full border-[3px] sm:border-[4px] border-white shadow-premium-darker sm:shadow-none size-12 sm:size-16 overflow-clip"
          >
            <img class="w-full" src="{{ imageSrc }}" alt="{{ body }}" width="80" height="80" />
          </div>
        </div>
      </ng-template>

      <div class="relative pb-8 sm:pb-0 sm:-bottom-8 sm:-mt-8 mb-4">
        <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 mx-auto px-8 max-w-96 sm:max-w-screen-md">
          <!-- 平常時 -->
          <!--
          <ng-container *ngTemplateOutlet="speachTemplate; context: {
            body: '過去問は3年分以上\n解きたい！',
            imageSrc: '/assets/images/lp/voice-thumb-student-2.png'
          }"></ng-container>
          <ng-container *ngTemplateOutlet="speachTemplate; context: {
            body: '気になる大学の\n出題傾向を分析したい！',
            imageSrc: '/assets/images/lp/voice-thumb-student-3.png'
          }"></ng-container>
          <ng-container *ngTemplateOutlet="speachTemplate; context: {
            body: '重たい過去問の本は\n持ち運ぶのが大変…',
            imageSrc: '/assets/images/lp/voice-thumb-student-4.png'
          }"></ng-container>
          -->

          <!-- 3ヶ月前 -->
          <ng-container
            *ngTemplateOutlet="
              speachTemplate;
              context: {
                body: '今すぐ志望校の\n過去問が欲しい！',
                imageSrc: '/assets/images/lp/voice-thumb-student-2.png'
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              speachTemplate;
              context: {
                body: '過去問は3年分以上\n解きたい！',
                imageSrc: '/assets/images/lp/voice-thumb-student-3.png'
              }
            "
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="
              speachTemplate;
              context: {
                body: '併願校の過去問を\nお得に入手したい！',
                imageSrc: '/assets/images/lp/voice-thumb-student-4.png'
              }
            "
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
