import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-lp-premium-feature-cards',
  templateUrl: './content-lp-premium-feature-cards.component.html',
  styleUrls: ['./content-lp-premium-feature-cards.component.scss']
})
export class ContentLpPremiumFeatureCardsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
