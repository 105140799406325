import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-lp-purchase-process',
  templateUrl: './content-lp-purchase-process.component.html',
  styleUrls: ['./content-lp-purchase-process.component.scss']
})
export class ContentLpPurchaseProcessComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
