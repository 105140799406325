import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, filter, shareReplay, startWith, take } from 'rxjs/operators';

// Redux
import { setBrowserTitle, dispatchAppError, dispatchInfoMessage, openWindow } from 'src/app/actions/core.actions';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import {
  findScienceProblemIds,
  findScienceProblem,
  findEnglishProblemIds,
  findEnglishProblem,
  findNationalLanguageProblemIds,
  findNationalLanguageProblemsById,
  findHistoryProblemIds,
  findHistoryProblem
} from 'src/app/actions/search.actions'; // TODO
import { findBookmarkProblemIds } from 'src/app/actions/bookmark.actions';
import * as BookmarkActions from 'src/app/actions/bookmark.actions';
import { RootState } from 'src/app/reducers';
import {
  getStaticMathData,
  getFetchedDate,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticEnglishData,
  getStaticNationalLanguageData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from 'src/app/selectors/static-data.selectors';
import {
  getMatchedProblemIds,
  getScienceProblem,
  getEnglishProblem,
  getNationalLanguageProblemsWithSameId,
  getHistoryProblem,
  getReadableMathProblem,
  getProblemSearching,
  getProblemIdsSearching,
  getFirstProblemIdFromMatchedProblemIds,
  getLastProblemIdFromMatchedProblemIds,
  getReadablePhysicsProblem,
  getReadableChemistryProblem,
  getReadableBiologyProblem,
  getReadableEnglishProblem,
  getReadableNationalLanguageProblemsFromProblems,
  getReadableJapaneseHistoryProblem,
  getReadableWorldHistoryProblem,
  getReadableGeographyProblem,
  getReadablePoliticalEconomyProblem
} from 'src/app/selectors/search.selectors';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import * as BookmarkSelectors from 'src/app/selectors/bookmark.selectors';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';

// Mapper
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { ReadableDataMapper } from 'src/app/mappers/readable-data-mapper';

// Models
import {
  ScienceProblem,
  ReadableScienceProblem,
  EnglishProblem,
  ReadableEnglishProblem,
  NationalLanguageProblem,
  ReadableNationalLanguageProblem,
  HistoryProblem,
  ReadableHistoryProblem,
  Problem
} from 'src/app/models/problem';
import { SearchProblemsCondition } from 'src/app/models/search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { User } from 'src/app/models/user';
import { AddBookmarkData, Bookmark, BookmarkProblem, DeleteBookmarkData } from 'src/app/models/bookmark';
import { Subject } from '../../../models/common-data';
import { SearchBookmarkCondition } from 'src/app/models/search-bookmark-condition';

// Utils
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';

// Config & Resources
import { fadeInOut, showHide } from 'src/app/resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { SubjectId, STATIC_DATA_CACHE_DAYS, META_VIEWPORT_OTHER, SCIENCE_IDS } from 'src/app/resources/config';
import { UAParser } from 'ua-parser-js';

// Error
import { GeneralError } from 'src/app/errors/general-error';
import { CustomErrorMessage } from 'src/app/errors/error-info';

type SearchProblemsConditionType =
  | SearchProblemsCondition<ScienceSearchCondition>
  | SearchProblemsCondition<EnglishSearchCondition>
  | SearchProblemsCondition<NationalLanguageSearchCondition>
  | SearchProblemsCondition<HistorySearchCondition>;

@Component({
  selector: 'app-problem-detail',
  templateUrl: './problem-detail.component.html',
  styleUrls: ['./problem-detail.component.scss'],
  animations: [fadeInOut, showHide]
})
export class ProblemDetailComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private subscriptions: Subscription[] = [];

  problemId$: Observable<string>;
  private searchProblemsCondition$: Observable<SearchProblemsConditionType>;
  scienceSearchProblemsCondition: SearchProblemsCondition<ScienceSearchCondition>;
  englishSearchProblemsCondition: SearchProblemsCondition<EnglishSearchCondition>;
  nationalLanguageSearchProblemsCondition: SearchProblemsCondition<NationalLanguageSearchCondition>;
  historySearchProblemsCondition: SearchProblemsCondition<HistorySearchCondition>;
  private problemIds$: Observable<string[]>;
  private problemIdsSearching$: Observable<boolean>;
  private problemSearching$: Observable<boolean>;
  private initializedBookmarkSubject = new BehaviorSubject(false);
  private bookmarkSearchCondition$: Observable<SearchBookmarkCondition>;
  private bookmarkProblemIds$: Observable<string[]>;

  scienceIds: string[] = SCIENCE_IDS;

  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticEnglishData$: Observable<StaticEnglishData>;
  staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;

  /** science-problems.component をそのまま利用するため array で定義 */
  readableScienceProblems$: Observable<ReadableScienceProblem[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;

  problem$: Observable<Problem>;
  scienceProblem$: Observable<ScienceProblem>;
  englishProblem$: Observable<EnglishProblem>;
  nationalLanguageProblem$: Observable<NationalLanguageProblem>;
  nationalLanguageProblems$: Observable<NationalLanguageProblem[]>;
  historyProblem$: Observable<HistoryProblem>;
  readableSortType$: Observable<string>;
  searching$: Observable<boolean>;
  isFirstProblem$: Observable<boolean>;
  isLastProblem$: Observable<boolean>;
  isPreconditionError = false;
  daimonMode$: Observable<boolean>;
  paperMode$: Observable<boolean>;
  trialMode$: Observable<boolean>;
  navigation$: Observable<boolean>;
  subjectId$: Observable<string>;
  menuType$: Observable<'article' | 'spellcheck'> = of('article');
  ENGLISH_SUBJECT_ID: string;
  MATH_SUBJECT_ID: string;
  NATIONAL_LANGUAGE_SUBJECT_ID: string;
  PHYSICS_SUBJECT_ID: string;
  CHEMISTRY_SUBJECT_ID: string;
  BIOLOGY_SUBJECT_ID: string;
  JAPANESE_HISTORY_SUBJECT_ID: string;
  WORLD_HISTORY_SUBJECT_ID: string;
  GEOGRAPHY_SUBJECT_ID: string;
  POLITICAL_ECONOMY_SUBJECT_ID: string;

  toolbarTitle = '問題詳細';

  signedInUser$: Observable<User>;
  user: User;
  bookmark: Bookmark;
  bookmarkSubjectProblems: BookmarkProblem[];
  initializedBookmark$ = this.initializedBookmarkSubject.asObservable();
  parentComponent: string;

  isFromBookmark$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {
    this.ENGLISH_SUBJECT_ID = SubjectId.ENGLISH;
    this.MATH_SUBJECT_ID = SubjectId.MATH;
    this.NATIONAL_LANGUAGE_SUBJECT_ID = SubjectId.NATIONAL_LANGUAGE;
    this.PHYSICS_SUBJECT_ID = SubjectId.PHYSICS;
    this.CHEMISTRY_SUBJECT_ID = SubjectId.CHEMISTRY;
    this.BIOLOGY_SUBJECT_ID = SubjectId.BIOLOGY;
    this.JAPANESE_HISTORY_SUBJECT_ID = SubjectId.JAPANESE_HISTORY;
    this.WORLD_HISTORY_SUBJECT_ID = SubjectId.WORLD_HISTORY;
    this.GEOGRAPHY_SUBJECT_ID = SubjectId.GEOGRAPHY;
    this.POLITICAL_ECONOMY_SUBJECT_ID = SubjectId.POLITICAL_ECONOMY;
  }

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setBrowserTitle({ subTitle: this.toolbarTitle }));
    this.setUpUser();
    this.setUpStaticData();
    this.setUpTrialMode();
    this.setUpNavigationAndPaperMode();
    this.setUpProblemId();

    this.setUpBookmarkFlag();
    this.isFromBookmark$.subscribe(isFromBookmark => {
      if (isFromBookmark) {
        // お気に入り問題詳細で必要な処理
        this.parentComponent = 'bookmarkProblemDetail';
        this.setupBookmarkSubjectId();
        this.setUpBookmarkSearchCondition();
        this.setUpBookmarkProblemIds();
        this.setUpBookmarkProblemPositionFlags();
        this.setUpBookmarkReadableSortType();
        this.setUpBookmarkSearchingFlag();
      } else {
        // 基礎検索問題詳細で必要な処理
        this.parentComponent = 'searchProblemDetail';
        this.setupSearchSubjectId();
        this.setUpSearchCondition();
        this.setUpSearchProblemIds();
        this.setUpSearchProblemPositionFlags();
        this.setUpSearchReadableSortType();
        this.setUpSearchSearchingFlag();
      }
    });

    this.setUpProblem();
    this.setUpReadableProblem();
    this.setUpBookmarks();
    this.setUpBrowserTitle();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
    this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
    this.store.dispatch(BookmarkActions.initializeFindBookmarkProblemIdsState());
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
  }

  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.staticMathData$ = this.store.select(getStaticMathData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPhysicsData$ = this.store.select(getStaticPhysicsData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticChemistryData$ = this.store.select(getStaticChemistryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticBiologyData$ = this.store.select(getStaticBiologyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticEnglishData$ = this.store.select(getStaticEnglishData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticNationalLanguageData$ = this.store.select(getStaticNationalLanguageData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticJapaneseHistoryData$ = this.store.select(getStaticJapaneseHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticWorldHistoryData$ = this.store.select(getStaticWorldHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticGeographyData$ = this.store.select(getStaticGeographyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPoliticalEconomyData$ = this.store.select(getStaticPoliticalEconomyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpTrialMode() {
    this.trialMode$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      map(signedInUser => (signedInUser.isTrial === true ? true : false))
    );
  }

  private setUpBookmarkFlag() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        if (Object.prototype.hasOwnProperty.call(params, 'from') && params.from === 'bookmark') {
          this.isFromBookmark$ = of(true);
        } else {
          this.isFromBookmark$ = of(false);
        }
      })
    );
  }

  private setUpNavigationAndPaperMode() {
    this.navigation$ = of(true);
    this.daimonMode$ = of(true);
    this.paperMode$ = of(false);

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        if (Object.prototype.hasOwnProperty.call(params, 'mode') && params.mode === 'paper') {
          this.navigation$ = of(false);
          this.daimonMode$ = of(false);
          this.paperMode$ = of(true);
        }
      })
    );
  }

  private setUpProblemId() {
    this.problemId$ = this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('problemId') || ''),
      shareReplay(1)
    );
    this.subscriptions.push(
      this.problemId$.subscribe(problemId => {
        this.isPreconditionError = problemId === '';
        if (!problemId) {
          const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
        }
      })
    );
  }

  private setupSearchSubjectId() {
    this.subjectId$ = this.activatedRoute.queryParams.pipe(
      map(queryParams => QueryParamsMapper.decodeSearchProblemsConditionQueryParams(queryParams)),
      // map(condition => condition as SearchProblemsCondition<ScienceSearchCondition>)
      map(condition => condition.subjectId)
    );
  }

  private setupBookmarkSubjectId() {
    this.signedInUser$.subscribe(user => {
      this.subjectId$ = this.activatedRoute.queryParams.pipe(
        map(queryParams => QueryParamsMapper.decodeBookmarkSearchProblemsCondition(queryParams, user.id)),
        map(condition => condition.subjectId)
      );
    });
  }

  private setUpSearchCondition() {
    this.searchProblemsCondition$ = this.activatedRoute.queryParams.pipe(
      map(queryParams => QueryParamsMapper.decodeSearchProblemsConditionQueryParams(queryParams)),
      // map(condition => condition as SearchProblemsCondition<ScienceSearchCondition>)
      map(condition => {
        if (this.scienceIds.includes(condition.subjectId)) {
          const _condition = condition as SearchProblemsCondition<ScienceSearchCondition>;
          this.scienceSearchProblemsCondition = _condition;
          return _condition;
        } else if (condition.subjectId === SubjectId.ENGLISH) {
          const _condition = condition as SearchProblemsCondition<EnglishSearchCondition>;
          this.englishSearchProblemsCondition = _condition;
          return _condition;
        } else if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          const _condition = condition as SearchProblemsCondition<NationalLanguageSearchCondition>;
          this.nationalLanguageSearchProblemsCondition = _condition;
          return _condition;
        } else if (condition.subjectId === SubjectId.JAPANESE_HISTORY || condition.subjectId === SubjectId.WORLD_HISTORY) {
          const _condition = condition as SearchProblemsCondition<HistorySearchCondition>;
          this.historySearchProblemsCondition = _condition;
          return _condition;
        }
      })
    );
  }

  private setUpBookmarkSearchCondition() {
    this.signedInUser$.subscribe(user => {
      this.bookmarkSearchCondition$ = this.activatedRoute.queryParams.pipe(
        map(queryParams => QueryParamsMapper.decodeBookmarkSearchProblemsCondition(queryParams, user.id))
      );
    });
  }

  private setUpBookmarkProblemIds() {
    this.bookmarkProblemIds$ = this.store.select(BookmarkSelectors.getMatchedBookmarkProblemIds).pipe(filter(it => it != null));
    this.subscriptions.push(
      this.bookmarkSearchCondition$.subscribe(searchCondition => {
        const condition: SearchBookmarkCondition = searchCondition;
        this.store.dispatch(findBookmarkProblemIds({ condition }));
      })
    );
  }

  private setUpSearchProblemIds() {
    this.problemIds$ = this.store.select(getMatchedProblemIds).pipe(filter(it => it != null));
    this.subscriptions.push(
      this.searchProblemsCondition$.subscribe(condition => {
        if (this.scienceIds.includes(condition.subjectId)) {
          this.store.dispatch(findScienceProblemIds({ condition: this.scienceSearchProblemsCondition }));
        } else if (condition.subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(findEnglishProblemIds({ condition: this.englishSearchProblemsCondition }));
        } else if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(findNationalLanguageProblemIds({ condition: this.nationalLanguageSearchProblemsCondition }));
        } else if (condition.subjectId === SubjectId.JAPANESE_HISTORY || condition.subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(findHistoryProblemIds({ condition: this.historySearchProblemsCondition }));
        }
      })
    );
  }

  private setUpProblem() {
    this.subjectId$.subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.scienceProblem$ = this.store.select(getScienceProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.scienceProblem$.pipe(problem => problem);
      } else if (subjectId === SubjectId.ENGLISH) {
        this.englishProblem$ = this.store.select(getEnglishProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.englishProblem$.pipe(problem => problem);
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.nationalLanguageProblems$ = this.store.select(getNationalLanguageProblemsWithSameId).pipe(
          filter(it => it != null),
          shareReplay(1),
          map(problems => problems)
        );
        this.problem$ = this.nationalLanguageProblems$.pipe(map(problems => problems[0]));
      } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
        this.historyProblem$ = this.store.select(getHistoryProblem).pipe(
          filter(it => it != null),
          shareReplay(1)
        );
        this.problem$ = this.historyProblem$.pipe(problem => problem);
      }
    });

    this.subscriptions.push(
      combineLatest([this.problemId$.pipe(filter(it => it !== '')), this.subjectId$, this.isFromBookmark$]).subscribe(
        ([problemId, subjectId, isFromBookmark]) => {
          // URLの問題IDが正しい値かどうかをチェック - 正しい場合のみAPIの呼び出し
          if (isFromBookmark) {
            this.bookmarkProblemIds$.subscribe(bookmarkProblemIds => {
              if (!bookmarkProblemIds.includes(problemId)) {
                this.setUpProblemIdError();
                return;
              }
            });
          } else {
            this.problemIds$.subscribe(problemIds => {
              if (!problemIds.includes(problemId)) {
                this.setUpProblemIdError();
                return;
              }
            });
          }
          this.isPreconditionError = false;

          // 問題を検索するAPIを呼び出す
          if (this.scienceIds.includes(subjectId)) {
            this.store.dispatch(findScienceProblem({ subjectId, problemId }));
          } else if (subjectId === SubjectId.ENGLISH) {
            this.store.dispatch(findEnglishProblem({ subjectId, problemId }));
          } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
            this.store.dispatch(findNationalLanguageProblemsById({ subjectId, problemId }));
          } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
            this.store.dispatch(findHistoryProblem({ subjectId, problemId }));
          }
        }
      )
    );
  }

  private setUpProblemIdError() {
    this.isPreconditionError = true;
    const error = GeneralError.customMessage(CustomErrorMessage.PROBLEM_DETAIL_FAILED_PRECONDITION);
    this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
  }

  private setUpSearchReadableSortType() {
    this.readableSortType$ = this.searchProblemsCondition$.pipe(
      map(condition => ReadableDataMapper.getSortTypeDisplayName(condition.sortType))
    );
  }

  private setUpBookmarkReadableSortType() {
    this.readableSortType$ = this.bookmarkSearchCondition$.pipe(
      map(condition => ReadableDataMapper.getBookmarkSortTypeDisplayName(condition.sortType))
    );
  }

  private setUpReadableProblem() {
    this.subjectId$.subscribe(subjectId => {
      if (this.scienceIds.includes(subjectId)) {
        this.readableScienceProblems$ = combineLatest([this.scienceProblem$]).pipe(
          switchMap(([problem]) => {
            const selector =
              subjectId === SubjectId.MATH
                ? this.store.select(getReadableMathProblem(problem))
                : subjectId === SubjectId.PHYSICS
                ? this.store.select(getReadablePhysicsProblem(problem))
                : subjectId === SubjectId.CHEMISTRY
                ? this.store.select(getReadableChemistryProblem(problem))
                : subjectId === SubjectId.BIOLOGY
                ? this.store.select(getReadableBiologyProblem(problem))
                : subjectId === SubjectId.GEOGRAPHY
                ? this.store.select(getReadableGeographyProblem(problem))
                : this.store.select(getReadablePoliticalEconomyProblem(problem));
            return selector;
          }),
          map(problem => [problem]),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.ENGLISH) {
        this.readableEnglishProblems$ = combineLatest([this.englishProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableEnglishProblem(problem));
            return selector;
          }),
          map(problem => [problem]),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.readableNationalLanguageProblems$ = combineLatest([this.nationalLanguageProblems$]).pipe(
          switchMap(([problems]) => {
            const selector = this.store.select(getReadableNationalLanguageProblemsFromProblems(problems));
            return selector;
          }),
          map(problems => problems),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        this.readableJapaneseHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableJapaneseHistoryProblem(problem));
            return selector;
          }),
          map(problem => [problem]),
          filter(it => it != null)
        );
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        this.readableWorldHistoryProblems$ = combineLatest([this.historyProblem$]).pipe(
          switchMap(([problem]) => {
            const selector = this.store.select(getReadableWorldHistoryProblem(problem));
            return selector;
          }),
          map(problem => [problem]),
          filter(it => it != null)
        );
      }
    });
  }

  private setUpSearchSearchingFlag() {
    this.problemIdsSearching$ = this.store.select(getProblemIdsSearching).pipe(startWith(true));
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemIdsSearching$, this.problemSearching$]).pipe(
      map(([idsSearching, problemSearching]) => idsSearching || problemSearching)
    );
  }

  private setUpBookmarkSearchingFlag() {
    this.problemIdsSearching$ = this.store.select(BookmarkSelectors.getBookmarkProblemIdsSearching).pipe(startWith(true));
    this.problemSearching$ = this.store.select(getProblemSearching).pipe(startWith(true));
    this.searching$ = combineLatest([this.problemIdsSearching$, this.problemSearching$]).pipe(
      map(([idsSearching, problemSearching]) => idsSearching || problemSearching)
    );
  }

  private setUpSearchProblemPositionFlags() {
    const problemId$ = this.problemId$.pipe(filter(it => it !== ''));
    this.isFirstProblem$ = combineLatest([
      problemId$,
      this.store.select(getFirstProblemIdFromMatchedProblemIds).pipe(filter(it => it != null))
    ]).pipe(map(([problemId, firstProblemId]) => problemId === firstProblemId));

    this.isLastProblem$ = combineLatest([
      problemId$,
      this.store.select(getLastProblemIdFromMatchedProblemIds).pipe(filter(it => it != null))
    ]).pipe(map(([problemId, lastProblemId]) => problemId === lastProblemId));
  }

  private setUpBookmarkProblemPositionFlags() {
    const problemId$ = this.problemId$.pipe(filter(it => it !== ''));
    this.isFirstProblem$ = combineLatest([
      problemId$,
      this.store.select(BookmarkSelectors.getFirstProblemIdFromMatchedBookmarkProblemIds).pipe(filter(it => it != null))
    ]).pipe(map(([problemId, firstProblemId]) => problemId === firstProblemId));

    this.isLastProblem$ = combineLatest([
      problemId$,
      this.store.select(BookmarkSelectors.getLastProblemIdFromMatchedBookmarkProblemIds).pipe(filter(it => it != null))
    ]).pipe(map(([problemId, lastProblemId]) => problemId === lastProblemId));
  }

  private setUpBookmarks() {
    const conditionParams = this.activatedRoute.snapshot.queryParams;
    this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
    this.store
      .select(getSignedInUser)
      .pipe(filter<User>(it => it != null && it !== 'none'))
      .pipe(take(1))
      .subscribe(user => {
        this.user = user;
        this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
        this.store.dispatch(BookmarkActions.findBookmark({ userId: user.id }));
        this.store
          .select(BookmarkSelectors.getBookmark)
          .pipe(
            filter(it => it != null),
            take(1)
          )
          .subscribe(bookmark => {
            this.bookmark = bookmark;
            this.bookmarkSubjectProblems = [];
            if (bookmark.problems !== undefined) {
              this.bookmarkSubjectProblems = this.bookmark.problems.filter(problem => problem.subjectId === conditionParams.subjectId);
            }
            this.store.dispatch(BookmarkActions.initializeFindBookmarkState());

            this.initializedBookmarkSubject.next(true);
          });
      });
  }

  private setUpBrowserTitle(menuType = 'article') {
    const subjects$: Observable<Subject[]> = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subjectId$.subscribe(subjectId => {
      let readableProblems$: Observable<
        ReadableScienceProblem[] | ReadableEnglishProblem[] | ReadableNationalLanguageProblem[] | ReadableHistoryProblem[]
      >;
      if (this.scienceIds.includes(subjectId)) {
        readableProblems$ = this.readableScienceProblems$;
      } else if (subjectId === SubjectId.ENGLISH) {
        readableProblems$ = this.readableEnglishProblems$;
      } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
        readableProblems$ = this.readableNationalLanguageProblems$;
      } else if (subjectId === SubjectId.JAPANESE_HISTORY) {
        readableProblems$ = this.readableJapaneseHistoryProblems$;
      } else if (subjectId === SubjectId.WORLD_HISTORY) {
        readableProblems$ = this.readableWorldHistoryProblems$;
      }

      if (readableProblems$) {
        combineLatest([subjects$, readableProblems$.pipe(filter(it => it !== null))]).subscribe(([subjects, problems]) => {
          const university: string = problems[0].university;
          const year: number = problems[0].year;
          const subject: string = SubjectUtil.getName(subjects, subjectId);
          const type = menuType === 'article' ? '問題' : '研究・解答';
          const title = `${university}　${year}年度　${subject}　${type}`;
          this.store.dispatch(setBrowserTitle({ subTitle: `${title}`, problemDetailFlag: true }));
        });
      }
    });
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  showPaper() {
    this.problemId$.pipe(take(1)).subscribe(problemId => {
      const { queryParams } = this.activatedRoute.snapshot;
      const url = RoutingPathResolver.resolveProblemDetail(problemId);
      this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, mode: 'paper' } } }));
    });
  }

  showPrint() {
    const ua = new UAParser(this.window.navigator.userAgent).getResult();
    if (ua.browser.name.indexOf('Safari') > -1) {
      document.execCommand('print', false, null);
    } else {
      (window as any).print();
    }
  }

  changeMenuType(event) {
    this.menuType$ = of(event);
    this.menuType$.pipe(take(1)).subscribe(menuType => this.setUpBrowserTitle(menuType));
  }

  addBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを登録します');
    const conditionParams = this.activatedRoute.snapshot.queryParams;
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを登録しています`
      })
    );

    const request: AddBookmarkData = {
      userId: this.user.id,
      subjectId: conditionParams.subjectId,
      problemId
    };

    Log.debug(this.LOG_SOURCE, 'add bookmark', request);
    this.store.dispatch(BookmarkActions.addBookmark(request));

    this.store
      .select(BookmarkSelectors.getAddBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `add bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを登録しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `add bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(BookmarkActions.initializeAddBookmarkState());
        this.setUpBookmarks();
      });
  }

  deleteBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入りを解除します');
    const conditionParams = this.activatedRoute.snapshot.queryParams;
    this.initializedBookmarkSubject.next(false);
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを解除しています`
      })
    );

    const request: DeleteBookmarkData = {
      userId: this.user.id,
      subjectId: conditionParams.subjectId,
      problemId
    };

    Log.debug(this.LOG_SOURCE, 'delete bookmark', request);
    this.store.dispatch(BookmarkActions.deleteBookmark(request));

    this.store
      .select(BookmarkSelectors.getDeleteBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `delete bookmark result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(BookmarkActions.initializeDeleteBookmarkState());
        this.setUpBookmarks();
      });
  }
}
