export enum Collection {
  COMMON_ID_USERS = 'commonIdUsers',
  USER = 'users',
  STATIC = 'statics',

  /** おすすめ問題セットの collection */
  PLAYLIST = 'playlists',

  /** プランの collection */
  PLAN = 'plans',

  /** 塾の collection */
  JUKU = 'jukus',

  /** 現在日時の collection */
  CURRENT_DATE_TIME = 'currentDateTime',

  /** お気に入りの collection */
  BOOKMARK = 'bookmarks',

  /** 検索ログファイルのメタデータ collection */
  SEARCH_LOG_FILE_METADATA = 'searchLogFileMetadata',

  /** メンテナンスモードの collection */
  MAINTENANCE_MODE = 'maintenanceMode',

  /** お気に入り大学の collection */
  COMMON_ID_UNIVERSITY_BOOKMARK = 'commonIdUniversityBookmarks',

  /** パスナビのあとで解くの collection */
  COMMON_ID_PAPER_BOOKMARK = 'commonIdPaperBookmarks',

  /** パスナビのおすすめ問題セットの collection */
  COMMON_ID_PLAYLIST = 'commonIdPlaylists',

  /** パスナビのおすすめ問題セットあとで解くの collection ?  */
  COMMON_ID_PLAYLIST_BOOKMARK = 'commonIdPlaylistBookmarks',

  /** 解答済み問題の collection */
  COMMON_ID_ANSWERED_PROBLEM = 'commonIdAnsweredProblems',

  /** プラン購入の collection */
  COMMON_ID_PURCHASES = 'commonIdPurchases'
}

export enum CallableFunction {
  REFRESH_TOKEN_USER = 'refreshTokenUser',
  CREATE_USER = 'createUser',
  UPDATE_USER = 'updateUser',
  UPDATE_USER_EMAIL = 'updateUserEmail',
  DELETE_USER = 'deleteUser',
  UPDATE_USER_IS_ACTIVE = 'updateUserIsActive',
  UPDATE_USER_IS_ORGANIZATION_ADMIN = 'updateUserIsOrganizationAdmin',
  UPDATE_USER_IS_TERMS_AGREE = 'updateUserIsTermsAgree',
  FIND_PROBLEM_COUNT = 'findProblemCount',
  FIND_PROBLEMS = 'findProblems',
  FIND_PROBLEMS_BY_ID = 'findProblemsById',
  FIND_PROBLEM_IDS = 'findProblemIds',
  FIND_PROBLEM = 'findProblem',
  FIND_PROBLEM_IMAGES = 'findProblemImages',
  FIND_PROBLEM_BY_PAPER_ID = 'findProblemByPaperId',
  FIND_PLAYLIST_PROBLEM_IDS = 'findPlaylistProblemIds',
  FIND_PLAYLIST_PROBLEMS = 'findPlaylistProblems',
  RECORD_APP_EVENT = 'recordAppEvent',
  CREATE_PLAN = 'createPlan',
  UPDATE_PLAN = 'updatePlan',
  DELETE_PLAN = 'deletePlan',
  CREATE_JUKU = 'createJuku',
  UPDATE_JUKU = 'updateJuku',
  DELETE_JUKU = 'deleteJuku',
  ASSIGN_PLAN = 'assignPlan',
  UNASSIGN_USER = 'unAssignPlan',
  ADD_BOOKMAR = 'addBookmark',
  DELETE_BOOKMARK = 'deleteBookmark',
  FIND_BOOKMARK_PROBLEMS = 'findBookmarkProblems',
  FIND_BOOKMARK_PROBLEM_IDS = 'findBookmarkProblemIds',
  SEND_BULK_MAIL = 'publishSendMailMembers',
  FIND_UNIVERSITY_COUNT = 'findUniversityCount',
  FIND_UNIVERSITY_PAPERS = 'findUniversityPapers',
  FIND_UNIVERSITY = 'findUniversity',
  PUBLISH_BULK_CREATE_USERS = 'publishBulkCreateUsers',
  UPDATE_DISABLE_RENEW_PLAN_ALERT = 'updateDisableRenewPlanAlert',
  GET_INFORMATIONS = 'getInformations',

  // 共通ID関連
  COMMON_ID_RECORD_APP_EVENT = 'commonIdRecordAppEvent',
  COMMON_ID_GET_CUSTOM_TOKEN = 'commonIdGetCustomToken',
  COMMON_ID_FIND_UNIVERSITY_COUNT = 'commonIdFindUniversityCount',
  COMMON_ID_FIND_UNIVERSITY_PAPERS = 'commonIdFindUniversityPapers',
  COMMON_ID_ADD_UNIVERSITY_BOOKMARK = 'commonIdAddUniversityBookmark',
  COMMON_ID_DELETE_UNIVERSITY_BOOKMARK = 'commonIdDeleteUniversityBookmark',
  COMMON_ID_ADD_PAPER_BOOKMARK = 'commonIdAddPaperBookmark',
  COMMON_ID_DELETE_PAPER_BOOKMARK = 'commonIdDeletePaperBookmark',
  COMMON_ID_FIND_UNIVERSITY = 'commonIdFindUniversity',
  COMMON_ID_GET_INFORMATIONS = 'commonIdGetInformations',
  COMMON_ID_FIND_PLAYLIST_PROBLEM_IDS = 'commonIdFindPlaylistProblemIds',
  COMMON_ID_FIND_PLAYLIST_PROBLEMS = 'commonIdFindPlaylistProblems',
  COMMON_ID_ADD_PLAYLIST_BOOKMARK = 'commonIdAddPlaylistBookmark',
  COMMON_ID_DELETE_PLAYLIST_BOOKMARK = 'commonIdDeletePlaylistBookmark',
  COMMON_ID_GET_STRIPE_CHECKOUT_URL = 'commonIdGetStripeCheckoutUrl',
  COMMON_ID_SAVE_PURCHASE = 'commonIdSavePurchase',
  COMMON_ID_SAVE_ANSWERED_PROBLEMS = 'commonIdSaveAnsweredProblems',
  COMMON_ID_UPDATE_USER_IS_ACTIVE = 'commonIdUpdateUserIsActive',
  COMMON_ID_UPDATE_USER = 'commonIdUpdateUser',
  COMMON_ID_FIND_PLAYLIST_PROBLEM_IDS_BY_PLAYLIST_BOOKMARK_IDS = 'commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds',
  COMMON_ID_FIND_BOOKMARK_AND_ANSWERED_PAPERS = 'commonIdFindMylistPapers',
  COMMON_ID_FIND_PLAY_LISTORIGINAL_PROBLEM_IDS = 'commonIdFindPlaylistOriginalProblemIds',

  // for testing tools ----------------
  TRANSFER_STATIC_DATA = 'transferStaticData',
  TRANSFER_PLAYLISTS = 'transferPlaylists',
  COMMON_ID_TRANSFER_PLAYLISTS = 'commonIdTransferPlaylists',
  FORCE_ROTATE_RAW_SEARCH_EVENT_GROUP = 'forceRotateRawSearchEventGroup',
  FORCE_EXPORT_SEARCH_EVENT_LOG = 'forceExportSearchEventLog',
  FORCE_AGGREGATE_DAILY_SEARCH_EVENT_LOG = 'forceAggregateDailySearchEventLog',
  FORCE_AGGREGATE_WEEKLY_SEARCH_EVENT_LOG = 'forceAggregateWeeklySearchEventLog',
  CLEAN_UP_RAW_SEARCH_EVENT_GROUP = 'cleanUpRawSearchEventGroup',
  FORCE_ROTATE_RAW_ADMIN_OPERATION_EVENT_GROUP = 'forceRotateRawAdminOperationEventGroup',
  FORCE_EXPORT_ADMIN_OPERATION_EVENT_LOG = 'forceExportAdminOperationEventLog',
  FORCE_AGGREGATE_DAILY_ADMIN_OPERATION_EVENT_LOG = 'forceAggregateDailyAdminOperationEventLog',
  FORCE_AGGREGATE_MONTHLY_ADMIN_OPERATION_EVENT_LOG = 'forceAggregateMonthlyAdminOperationEventLog',
  CLEAN_UP_RAW_ADMIN_OPERATION_EVENT_GROUP = 'cleanUpRawAdminOperationEventGroup',
  FORCE_BACKUP_FIRESTORE = 'forceBackupFirestore',
  COMMON_ID_AGGREGATE_NUMBER_OF_USERS = 'commonIdAggregateNumberOfUsers',
  CHECK_BOOKMARK_MAX_COUNT = 'checkBookmarkMaxCount',
  CHECK_UNIVERSITY_BOOKMARK_MAX_COUNT = 'checkUniversityBookmarkMaxCount',
  CHECK_PAPER_BOOKMARK_MAX_COUNT = 'checkPaperBookmarkMaxCount',
  CHECK_ANSWERED_PROBLEMS_MAX_COUNT = 'checkAnsweredProblemsMaxCount',
  CHECK_PLAYLIST_BOOKMARK_MAX_COUNT = 'checkPlaylistBookmarkMaxCount',
  PUBLISH_BULK_DELETE_USERS = 'publishBulkDeleteUsers'
}
