<header class="top-header" [attr.data-is-show]="headerIsShow">
  <div class="top-header-inner">
    <div class="top-header-logo">
      <img src="assets/images/app-logo-for-school-with-company-horizontal.svg" alt="入試正解デジタル for School" width="510" height="98" />
    </div>
    <div class="top-header-buttons">
      <button class="max-xs:text-xs" mat-flat-button color="primary" (click)="newInstallationUrlClickHandler()">
        新規導入の申し込み(高校向け)
      </button>
      <button class="max-xs:text-xs inline-block border-primary text-primary md:w-40" mat-stroked-button (click)="gotoSearch()">
        ログイン
      </button>
    </div>
  </div>
</header>
