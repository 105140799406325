import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-overflow-wrapper-x',
  templateUrl: './overflow-wrapper-x.component.html',
  styleUrls: ['./overflow-wrapper-x.component.scss']
})
export class OverflowWrapperXComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() snap = false;
  @Input() snapPaddingLeft?: string;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  isScrolled = false;
  isScrolledEnd = false; // スクロールが終点に到達したかどうか
  isOverflowing = false; // スクロールが必要かどうか

  private wrapperElement;
  // ブラウザの端数処理の違いを吸収するための許容値（ピクセル）
  private readonly SCROLL_THRESHOLD = 1;

  constructor() {}
  ngOnInit() {}

  ngAfterViewInit() {
    this.wrapperElement = this.wrapper.nativeElement;
    this.wrapperElement.addEventListener('scroll', this.checkScroll.bind(this));
    // 初期状態の確認（非同期コンテンツのロードに対応するため複数回実行）
    setTimeout(() => this.checkScroll(), 100);
    setTimeout(() => this.checkScroll(), 500); // 初期状態の確認
    setTimeout(() => this.checkScroll(), 1000);
  }

  ngOnDestroy() {
    if (this.wrapperElement) {
      this.wrapperElement.removeEventListener('scroll', this.checkScroll.bind(this));
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScroll(); // ウィンドウサイズ変更時にスクロール状態をチェック
  }

  checkScroll() {
    const element = this.wrapper.nativeElement;
    this.isScrolled = element.scrollLeft > 0;
    this.checkOverflow(); // 右端のはみ出し状態をチェック
  }

  checkOverflow() {
    const element = this.wrapper.nativeElement;
    // 小数点以下の端数を切り上げて計算
    const scrollWidth = Math.ceil(element.scrollWidth);
    const clientWidth = Math.ceil(element.clientWidth);
    const scrollLeft = Math.ceil(element.scrollLeft);

    this.isOverflowing = scrollWidth > clientWidth;

    if (!this.isOverflowing) {
      this.isScrolledEnd = true;
    } else {
      // 残りのスクロール量を計算し、許容値以下なら終端と判定
      const remainingScroll = scrollWidth - (clientWidth + scrollLeft);
      this.isScrolledEnd = remainingScroll <= this.SCROLL_THRESHOLD;
    }
  }
}
