<app-wrapper size="lg" isFitWithSmallScreen="true">
  <div @enter *ngIf="(loaded$ | async) === true">
    <app-content-row>
      <app-wrapper size="fluid" isFitWithSmallScreen="false">
        <div class="pt-4 md:pt-8">
          <div class="flex flex-col md:flex-row flex-wrap items-center gap-2 md:gap-4 justify-center">
            <div class="shadow-black-whisper size-12 bg-white rounded-lg flex items-center justify-center shrink-0">
              <app-icon-premium class="text-yellow-gold text-2xl leading-none"></app-icon-premium>
            </div>
            <h1 class="mb-0 text-3xl md:text-4xl font-black">入試正解<br class="md:hidden" />デジタルプレミアム</h1>
          </div>
          <h2 class="text-center text-2xl md:text-3xl text-black font-semibold tracking-widest mt-4 mb-6 md:mt-6 md:mb-10">プラン選択</h2>
          <div class="mx-4 md:mx-0 mb-8">
            <app-content-lp-pricing-sections
              parentComponent="purchase"
              [isPremiumUser]="isPremiumUser$ | async"
              (gotoStripeClick)="gotoStripeClick($event)"
            ></app-content-lp-pricing-sections>
            <ng-container *ngTemplateOutlet="notesOnPurchase"></ng-container>
          </div>
        </div>
      </app-wrapper>
    </app-content-row>

    <app-content-row>
      <app-wrapper size="fluid" isFitWithSmallScreen="true">
        <app-card-content [showSeparator]="false">
          <div class="bg-gradient-to-b from-yellow-gold/20 absolute top-0 left-0 w-full h-64 z-0 pointer-events-none">
            <!-- 金色のグラデーション -->
          </div>
          <div class="relative z-10">
            <app-wrapper size="fluid">
              <div class="px-4 py-8 md:p-16">
                <div class="space-y-16 md:space-y-20">
                  <div class="space-y-10">
                    <h2 class="text-center mb-0 text-2xl md:text-3xl text-black font-semibold tracking-widest">プレミアムの特長</h2>
                    <p class="text-md md:text-base text-center leading-8 tracking-wider">
                      10科目対応で、苦手科目対策から志望校対策まで徹底的に演習ができます。<br />
                      志望校を変更しても、追加購入の必要がなく安心です。<br />
                      期間ごとにプランを選んで、自分の好きなペースで学習をしましょう。
                    </p>
                    <div>
                      <app-content-lp-premium-feature-cards></app-content-lp-premium-feature-cards>
                    </div>
                  </div>
                  <div>
                    <app-content-lp-functional-comparison (gotoFaqClick)="gotoFaqClick($event)"></app-content-lp-functional-comparison>
                  </div>
                  <div>
                    <app-content-lp-purchase-process></app-content-lp-purchase-process>
                  </div>
                </div>
              </div>
            </app-wrapper>
          </div>
        </app-card-content>
      </app-wrapper>
    </app-content-row>

    <app-wrapper size="fluid" isFitWithSmallScreen="false">
      <app-content-row>
        <h2 class="text-center text-2xl md:text-3xl text-black font-semibold tracking-widest mt-8 mb-6 md:mt-10 md:mb-10">プラン選択</h2>
        <div class="mx-4 md:mx-0 mb-8">
          <app-content-lp-pricing-sections
            parentComponent="purchase"
            [isPremiumUser]="isPremiumUser$ | async"
            (gotoStripeClick)="gotoStripeClick($event)"
          ></app-content-lp-pricing-sections>
          <ng-container *ngTemplateOutlet="notesOnPurchase"></ng-container>
        </div>
      </app-content-row>
    </app-wrapper>

    <app-content-row>
      <app-wrapper size="fluid" isFitWithSmallScreen="true">
        <app-card-content [showSeparator]="true">
          <app-wrapper size="fluid">
            <app-content-row>
              <section class="section-basic section-faq">
                <h1>よくあるご質問</h1>
                <div class="section-basic-inner">
                  <h3>サービス全般について</h3>
                  <div class="-mx-2 mx-0">
                    <div class="logs" *ngFor="let faq of category1Faqs">
                      <div [id]="faq.id" class="mb-4 md:mb-6">
                        <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                          <div class="mt-0" [innerHTML]="faq.body"></div>
                        </app-faq-list-item>
                      </div>
                    </div>
                  </div>
                  <h3>掲載内容について</h3>
                  <div class="-mx-2 mx-0">
                    <div class="logs" *ngFor="let faq of category2Faqs">
                      <div [id]="faq.id" class="mb-4 md:mb-6">
                        <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                          <div class="mt-0" [innerHTML]="faq.body"></div>
                        </app-faq-list-item>
                      </div>
                    </div>
                  </div>
                  <h3>プレミアムについて</h3>
                  <div class="-mx-2 mx-0">
                    <div class="logs" *ngFor="let faq of category3Faqs">
                      <div [id]="faq.id" class="mb-4 md:mb-6">
                        <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                          <div class="mt-0" [innerHTML]="faq.body"></div>
                        </app-faq-list-item>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </app-content-row>
          </app-wrapper>
        </app-card-content>
      </app-wrapper>
    </app-content-row>
  </div>
</app-wrapper>

<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>

<ng-template #notesOnPurchase>
  <div class="mt-6">
    <p class="text-xs md:text-sm">
      <strong>【購入時のご注意】</strong><br />
      ※ 購入いただく前に、ご希望の入試問題が掲載されているか「<a
        class="text-primary underline hover:no-underline cursor-pointer"
        (click)="gotoSearchUniv()"
        >大学から探す</a
      >」より該当の大学を検索して、掲載有無をご確認ください。 詳細は、<a
        class="text-primary underline hover:no-underline cursor-pointer"
        (click)="gotoFaq('faq-11')"
        >こちらのFAQ</a
      >をご確認ください。<br />
      ※ 購入いただいたにもかかわらず、プレミアム機能が使えない場合はお手数ですが
      <a class="text-primary underline hover:no-underline cursor-pointer" (click)="gotoFaq('faq-12')">こちらのFAQ</a>
      をご確認ください。
    </p>
  </div>
</ng-template>
