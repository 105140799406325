import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CallableFunction, Collection } from '../resources/app-firebase';
import { Bookmark } from '../models/bookmark';
import { AddBookmarkRequest } from '../models/add-bookmark-request';
import { DeleteBookmarkRequest } from '../models/delete-bookmark-request';
import { AddBookmarkResponse } from '../models/add-bookmark-response';
import { DeleteBookmarkResponse } from '../models/delete-bookmark-response';
import { SearchBookmarkCountResponse } from '../models/search-bookmark-count-response';
import { EnglishProblem, HistoryProblem, NationalLanguageProblem, ScienceProblem } from '../models/problem';
import { SearchBookmarkCondition } from '../models/search-bookmark-condition';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  findBookmark(userId: string): Observable<Bookmark> {
    return this.afs
      .collection<Bookmark>(Collection.BOOKMARK, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? ({} as Bookmark)
            : snapshot.docs.map(doc => {
                const bookmarkId: string = doc.id;
                const data = doc.data();
                data.id = bookmarkId;
                return data as Bookmark;
              })[0]
        )
      );
  }

  addBookmark(userId: string, subjectId: string, problemId: string): Observable<AddBookmarkResponse> {
    const req: AddBookmarkRequest = { userId, subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.ADD_BOOKMAR);
    return callable(req);
  }

  deleteBookmark(userId: string, subjectId: string, problemId: string): Observable<DeleteBookmarkResponse> {
    const req: DeleteBookmarkRequest = { userId, subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.DELETE_BOOKMARK);
    return callable(req);
  }

  findBookmarkCount(userId: string, subjectId: string): Observable<SearchBookmarkCountResponse> {
    return this.afs
      .collection<Bookmark>(Collection.BOOKMARK, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot => {
          const count = 0;
          if (snapshot.empty) return { count };

          const bookmark: Bookmark = snapshot.docs[0].data() as Bookmark;
          return { count: bookmark.problems.filter(problem => problem.subjectId === subjectId).length };
        })
      );
  }

  findEnglishBookmarkProblems(condition: SearchBookmarkCondition): Observable<EnglishProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findMathBookmarkProblems(condition: SearchBookmarkCondition): Observable<ScienceProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findNationalLanguageBookmarkProblems(condition: SearchBookmarkCondition): Observable<NationalLanguageProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findPhysicsBookmarkProblems(condition: SearchBookmarkCondition): Observable<ScienceProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findChemistryBookmarkProblems(condition: SearchBookmarkCondition): Observable<ScienceProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findBiologyBookmarkProblems(condition: SearchBookmarkCondition): Observable<ScienceProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findJapaneseHistoryBookmarkProblems(condition: SearchBookmarkCondition): Observable<HistoryProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findWorldHistoryBookmarkProblems(condition: SearchBookmarkCondition): Observable<HistoryProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findGeographyBookmarkProblems(condition: SearchBookmarkCondition): Observable<ScienceProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findPoliticalEconomyBookmarkProblems(condition: SearchBookmarkCondition): Observable<ScienceProblem[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEMS);
    return callable(condition);
  }

  findBookmarkProblemIds(condition: SearchBookmarkCondition): Observable<string[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_BOOKMARK_PROBLEM_IDS);
    return callable(condition);
  }
}
