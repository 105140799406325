<div class="entrance">
  <div class="entrance-fv">
    <div class="entrance-fv-image">
      <h1>
        <img src="/assets/images/lp/entrance/logo.svg" alt="大学入試過去問Webサービス 旺文社 入試正解デジタル" class="logo" width="412  " />
      </h1>
      <img src="/assets/images/lp/entrance/main.png" alt="旺文社 入試正解デジタル 過去問題集" class="main-image" />
    </div>
    <p class="text-primary fv-text-title font-bold mt-8 hidden md:block">受験対策をもっと効率的に</p>
    <p class="fv-text font-bold mt-8 hidden md:block">
      旺文社が刊行する『全国大学入試問題正解』の過去問、解答・解説を掲載。<br />
      全国200大学以上、10科目（英語、数学、国語、物理、化学、生物、日本史、世界史、地理、政治・経済）が閲覧できます。
    </p>
  </div>
  <div class="entrance-block">
    <div class="entrance-block-inner">
      <a class="entrance-block-inner-hit" (click)="gotoBToCLp()">
        <h2 class="font-bold">
          高校生・大学受験生はこちら
        </h2>
        <h3><img src="/assets/images/app-logo-with-company.svg" alt="旺文社 入試正解デジタル for school" width="240" /></h3>
        <img src="/assets/images/lp/entrance/icon-student.png" alt="旺文社 入試正解デジタル高校生・大学受験生用" width="220" class="mt-4" />
        <ul class="font-bold">
          <li><mat-icon class="text-primary">check_circle</mat-icon>志望校の過去問対策</li>
          <li><mat-icon class="text-primary">check_circle</mat-icon>マイリストによる学習進捗管理</li>
          <li><mat-icon class="text-primary">check_circle</mat-icon>プレミアム登録で最大10年分の過去問が解ける</li>
        </ul>
      </a>

      <a class="entrance-block-inner-hit" (click)="gotoBToSLp()">
        <h2 class="font-bold">
          学校・塾の先生はこちら
        </h2>
        <h3><img src="/assets/images/app-logo-for-school-with-company.svg" alt="旺文社 入試正解デジタル" width="214" /></h3>
        <img src="/assets/images/lp/entrance/icon-school.png" alt="旺文社 入試正解デジタル for school" width="190" class="mt-4" />
        <ul class="font-bold">
          <li><mat-icon class="text-primary">check_circle</mat-icon>各科目に特化した過去問検索</li>
          <li><mat-icon class="text-primary">check_circle</mat-icon>入試問題を使ったプリント作成ができる</li>
        </ul>
      </a>
    </div>
  </div>
</div>
<app-common-id-footer footerTypeEntrance="true"></app-common-id-footer>
