import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-overflow-wrapper-y',
  templateUrl: './overflow-wrapper-y.component.html',
  styleUrls: ['./overflow-wrapper-y.component.scss']
})
export class OverflowWrapperYComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() snap = false;
  @Input() snapPaddingTop?: string;
  @Input() maxHeight = '100%'; // max-heightのデフォルト値を追加
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  isScrolled = false;
  isScrolledEnd = false; // スクロールが終点に到達したかどうか
  isOverflowing = false; // スクロールが必要かどうか

  private wrapperElement;

  constructor() {}
  ngOnInit() {}

  ngAfterViewInit() {
    this.wrapperElement = this.wrapper.nativeElement;
    this.wrapperElement.addEventListener('scroll', this.checkScroll.bind(this));
    setTimeout(() => this.checkScroll(), 500); // 初期状態の確認
  }

  ngOnDestroy() {
    this.wrapperElement.removeEventListener('scroll');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScroll(); // ウィンドウサイズ変更時にスクロール状態をチェック
  }

  checkScroll() {
    const element = this.wrapper.nativeElement;
    this.isScrolled = element.scrollTop > 0;
    this.checkOverflow(); // 下端のはみ出し状態をチェック
  }

  checkOverflow() {
    const element = this.wrapper.nativeElement;
    this.isOverflowing = element.scrollHeight > element.clientHeight;
    if (!this.isOverflowing || element.scrollHeight === element.clientHeight + element.scrollTop) {
      this.isScrolledEnd = true;
    } else {
      this.isScrolledEnd = false;
    }
  }
}
