<div class="flex flex-col md:flex-row gap-6 md:gap-8">
  <div class="w-full shrink bg-white rounded-2xl relative border-2 border-border-line overflow-clip">
    <div class="mx-auto pt-8 min-h-24">
      <p class="text-base sm:text-md md:text-base font-bold text-center tracking-wider">
        <span class="text-primary">200</span>大学以上・最大<span class="text-primary">10年分</span><br />
        過去問を<br class="md:hidden" /><span class="text-primary">無制限</span>で閲覧！
      </p>
    </div>
    <img
      class="mx-auto max-w-[60%] md:max-w-[80%]"
      src="/assets/images/lp/student/premium01.jpg"
      alt="200大学以上・最大10年分過去問を無制限で閲覧！"
      width="414"
      height="267"
    />
  </div>
  <div class="w-full shrink bg-white rounded-2xl relative border-2 border-border-line overflow-clip">
    <div class="mx-auto pt-8 min-h-24">
      <p class="text-base sm:text-md md:text-base font-bold text-center tracking-wider">
        <span class="text-primary">プロ厳選</span><br class="md:hidden" />おすすめ問題<br />
        セットが<span class="text-primary">解き放題！</span>
      </p>
    </div>
    <img
      class="mx-auto max-w-[60%] md:max-w-[80%]"
      src="/assets/images/lp/student/premium02.jpg"
      alt="プロ厳選おすすめ問題セットが解き放題！"
      width="414"
      height="267"
    />
  </div>
  <div class="w-full shrink bg-white rounded-2xl relative border-2 border-border-line overflow-clip">
    <div class="mx-auto pt-8 min-h-24">
      <p class="text-base sm:text-md md:text-base font-bold text-center tracking-wider">
        複数年度の問題から<br />
        <span class="text-primary">分野を絞って集中対策</span><br class="md:hidden" />できる！
      </p>
    </div>
    <img
      class="mx-auto max-w-[60%] md:max-w-[80%]"
      src="/assets/images/lp/student/premium03.jpg"
      alt="複数年度の問題から分野を絞って集中対策できる！"
      width="414"
      height="267"
    />
  </div>
</div>
