<header class="sign-in-header">
  <div class="sign-in-header-top">
    <div class="sign-in-header-logo" *ngIf="(user$ | async)?.isTrial !== true">
      <img src="assets/images/app-logo-for-school.svg" alt="入試正解デジタル for School" />
    </div>
    <div class="sign-in-header-logo" *ngIf="(user$ | async)?.isTrial === true">
      <img src="assets/images/app-logo-for-school-taikenban.svg" alt="入試正解デジタル for School 体験版" />
    </div>
    <div
      class="sign-in-header-user-info"
      tabindex="1"
      (touchend)="touchendUserInfoDropdown()"
      (mouseenter)="hoverUserInfoDropdown()"
      (mouseleave)="blurUserInfoParent()"
      (focus)="focusUserInfoParent()"
      (blur)="blurUserInfoParent()"
      [ngClass]="{
        isShow: showUserInfoDropdown(),
        isHover: showUserInfoDropdown()
      }"
    >
      <!-- ▼ PCここから -->
      <div class="sign-in-header-user-info-label">
        <div class="user">
          <div class="school">{{ (user$ | async)?.school }}</div>
          <div class="name">
            <!-- ▼ 有効期限が残り60日〜1日 -->
            <div class="flex items-center gap-1 ml-auto text-primary text-sm mr-1 mt-1" *ngIf="isSchoolOrJuku && isPlanExpiredWithin60">
              <mat-icon class="text-[1rem] w-[1rem] h-[1rem]">error</mat-icon>
              <span>残り{{ planDaysRemained }}日</span>
            </div>
            <!-- △ 有効期限が残り60日〜1日 -->
            <div class="label" *ngIf="(user$ | async)?.isAdmin">Admin</div>
            <div class="label" *ngIf="(user$ | async)?.isOrganizationAdmin">団体管理ユーザー</div>
            <div class="label" *ngIf="!(user$ | async)?.isAdmin && !(user$ | async)?.isOrganizationAdmin">一般ユーザー</div>
            <div class="family-name">{{ (user$ | async)?.familyName }}</div>
            <div class="first-name">{{ (user$ | async)?.firstName }}</div>
            <div class="honorifics">さん</div>
          </div>
        </div>
        <div class="dropdown-icon"><mat-icon aria-hidden="false">arrow_drop_down</mat-icon></div>
      </div>
      <!-- △ PCここから -->
      <!-- ▼ SPここから -->
      <div class="sign-in-header-user-info-label-sp">
        <!-- ▼ 有効期限が残り60日〜1日(SP) -->
        <div *ngIf="isSchoolOrJuku && isPlanExpiredWithin60">
          <span class="flex items-center gap-[0.1rem] mr-2 text-xs text-primary">
            <mat-icon class="text-[0.75rem] w-[0.75rem] h-[0.75rem]">error</mat-icon>
            <span>残り{{ planDaysRemained }}日</span>
          </span>
        </div>
        <!-- △ 有効期限が残り60日〜1日(SP) -->
        <div class="user-icon"><mat-icon aria-hidden="false">account_circle</mat-icon></div>
        <div class="dropdown-icon"><mat-icon aria-hidden="false">arrow_drop_down</mat-icon></div>
      </div>
      <!-- △ SPここから -->
      <div class="sign-in-header-user-info-dropdown">
        <div class="user px-4 py-2 mb-2 sm:hidden">
          <div class="school text-left ml-1">{{ (user$ | async)?.school }}</div>
          <div class="name">
            <!--
            <div class="label" *ngIf="(user$ | async)?.isAdmin">Admin</div>
            <div class="label" *ngIf="(user$ | async)?.isOrganizationAdmin">団体管理ユーザー</div>
            -->
            <div class="family-name">{{ (user$ | async)?.familyName }}</div>
            <div class="first-name">{{ (user$ | async)?.firstName }}</div>
            <div class="honorifics">さん</div>
          </div>
        </div>
        <dl class="sign-in-header-user-info-dropdown-plan-table">
          <div>
            <dt>権限</dt>
            <dd>
              <ng-container *ngIf="user$ | async as user">
                <span *ngIf="user.isAdmin">Admin</span>
                <span *ngIf="user.isOrganizationAdmin">団体管理ユーザー</span>
                <span *ngIf="!user.isAdmin && !user.isOrganizationAdmin">一般ユーザー</span>
              </ng-container>
            </dd>
          </div>
          <div>
            <dt>科目</dt>
            <dd>
              <div class="flex flex-wrap gap-x-[0.375rem]" [innerHTML]="visibleSubjectNames$ | async"></div>
            </dd>
          </div>
          <div *ngIf="isSchoolOrJuku">
            <dt>有効期限</dt>
            <dd>
              <!-- ▼ 通常時 -->
              <div class="flex items-center" *ngIf="!isPlanUnassigned && !isPlanExpiredWithin60">{{ planEndAt }}</div>
              <!-- △ 通常時 -->

              <!-- ▼ 有効期限が残り60日〜1日 (一般ユーザーの場合) -->
              <div
                class="flex items-center gap-2 text-primary"
                *ngIf="!(user$ | async)?.isOrganizationAdmin && !(user$ | async)?.isAdmin && isPlanExpiredWithin60"
              >
                <span>{{ planEndAt }}</span>
                <span class="flex items-center gap-1 ml-auto">
                  <mat-icon class="text-[1rem] w-[1rem] h-[1rem]">error</mat-icon>
                  <span>残り{{ planDaysRemained }}日</span>
                </span>
              </div>
              <!-- △ 有効期限が残り60日〜1日 (一般ユーザーの場合) -->

              <!-- ▼ 有効期限が残り60日〜1日 (団体管理ユーザーの場合)-->
              <a
                (click)="showPlans()"
                (touchstart)="showPlans()"
                class="flex items-center gap-2 text-primary underline hover:no-underline block cursor-pointer"
                *ngIf="(user$ | async)?.isOrganizationAdmin && isPlanExpiredWithin60"
              >
                <span>{{ planEndAt }}</span>
                <span class="flex items-center gap-1 ml-auto">
                  <mat-icon class="text-[1rem] w-[1rem] h-[1rem]">error</mat-icon>
                  <span>残り{{ planDaysRemained }}日</span>
                </span>
              </a>
              <!-- △ 有効期限が残り60日〜1日 (団体管理ユーザーの場合) -->

              <!-- ▼ 残り0日 (一般ユーザーの場合)  -->
              <div
                class="text-nega leading-4"
                *ngIf="!(user$ | async)?.isOrganizationAdmin && !(user$ | async)?.isAdmin && isPlanUnassigned"
              >
                有効なプランが割り当てられていません
              </div>
              <!-- △ 残り0日 (一般ユーザーの場合)  -->

              <!-- ▼ 残り0日  (団体管理ユーザーの場合) -->
              <a
                (click)="showPlans()"
                (touchstart)="showPlans()"
                class="text-primary underline hover:no-underline block cursor-pointer"
                *ngIf="(user$ | async)?.isOrganizationAdmin && isPlanUnassigned"
              >
                契約プランの確認
              </a>
              <!-- △ 残り0日  (団体管理ユーザーの場合) -->
            </dd>
          </div>
        </dl>
        <hr class="border-t border-gray-50" />
        <button class="item" (click)="signOut()" matRipple tabindex="1" (focus)="focusUserInfoItem(0)" (blur)="blurUserInfoItem(0)">
          <mat-icon class="icon">logout</mat-icon>
          <span class="label">サインアウト</span>
        </button>
      </div>
    </div>
  </div>
  <div class="border-b border-gray-50"></div>
  <app-overflow-wrapper-x [snap]="true" [snapPaddingLeft]="'1rem'">
    <nav class="sign-in-gnav">
      <div mat-tab-nav-bar class="sign-in-gnav-list" [disableRipple]="true">
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showSearch()"
          [active]="isCurrentSearch()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">search</mat-icon>
          基本検索
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showUniversitySearch()"
          [active]="isCurrentUniversitySearch()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">location_city</mat-icon>
          大学検索
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showPlaylists()"
          [active]="isCurrentPlaylists()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">view_module</mat-icon>
          おすすめ問題セット
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showBookmarkProblems()"
          [active]="isCurrentBookmarkProblems()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">bookmarks</mat-icon>
          お気に入り管理
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showPlans()"
          [active]="isCurrentPlans()"
          *ngIf="(user$ | async)?.isOrganizationAdmin && isSchoolOrJuku"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">manage_accounts</mat-icon>
          プラン・アカウント管理
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showAdmin()"
          [active]="isCurrentAdmin()"
          *ngIf="(user$ | async)?.isAdmin"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">settings</mat-icon>
          Admin機能
        </button>
      </div>
      <div class="sign-in-gnav-icons">
        <div class="sign-in-gnav-icons-list">
          <button
            class="sign-in-gnav-icons-list-button"
            mat-icon-button
            matTooltip="お知らせ"
            (click)="showInformation()"
            matBadge="!"
            matBadgeSize="small"
            [matBadgeHidden]="(newInformation$ | async) !== true"
          >
            <mat-icon class="icon" fontSet="material-icons-outlined">notifications</mat-icon>
          </button>
          <button class="sign-in-gnav-icons-list-button" mat-icon-button matTooltip="ヘルプ" (click)="showHelp()">
            <mat-icon class="icon">help_outline</mat-icon>
          </button>
        </div>
      </div>
    </nav>
  </app-overflow-wrapper-x>
</header>
