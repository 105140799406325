import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as BookmarkActions from '../actions/bookmark.actions';
import { BookmarkService } from '../services/bookmark.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';
import { AppError } from '../errors/app-error';

@Injectable()
export class BookmarkEffects {
  private LOG_SOURCE = this.constructor.name;

  addBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.addBookmark),
      switchMap(action =>
        this.bookmarkService.addBookmark(action.userId, action.subjectId, action.problemId).pipe(
          map(response => BookmarkActions.addBookmarkSuccess({ response })),
          catchError(e => of(BookmarkActions.addBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );
  deleteBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.deleteBookmark),
      switchMap(action =>
        this.bookmarkService.deleteBookmark(action.userId, action.subjectId, action.problemId).pipe(
          map(response => BookmarkActions.deleteBookmarkSuccess({ response })),
          catchError(e => of(BookmarkActions.deleteBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );
  findBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findBookmark),
      switchMap(action =>
        this.bookmarkService.findBookmark(action.userId).pipe(
          map(bookmark => BookmarkActions.findBookmarkSuccess({ bookmark })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(BookmarkActions.findBookmarkFailure(), dispatchAppError({ source: this.LOG_SOURCE, error }));
          })
        )
      )
    )
  );
  findBookmarkCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findBookmarkCount),
      switchMap(action =>
        this.bookmarkService.findBookmarkCount(action.userId, action.subjectId).pipe(
          map(response => BookmarkActions.findBookmarkCountSuccess({ response })),
          catchError(e =>
            of(
              BookmarkActions.findBookmarkCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findEnglishBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findEnglishBookmark),
      switchMap(action =>
        this.bookmarkService.findEnglishBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findEnglishBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findEnglishBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findMathBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findMathBookmark),
      switchMap(action =>
        this.bookmarkService.findMathBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findMathBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findMathBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findNationalLanguageBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findNationalLanguageBookmark),
      switchMap(action =>
        this.bookmarkService.findNationalLanguageBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findNationalLanguageBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findNationalLanguageBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findPhysicsBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findPhysicsBookmark),
      switchMap(action =>
        this.bookmarkService.findPhysicsBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findPhysicsBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findPhysicsBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findChemistryBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findChemistryBookmark),
      switchMap(action =>
        this.bookmarkService.findChemistryBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findChemistryBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findChemistryBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findBiologyBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findBiologyBookmark),
      switchMap(action =>
        this.bookmarkService.findBiologyBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findBiologyBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findBiologyBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findJapaneseHistoryBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findJapaneseHistoryBookmark),
      switchMap(action =>
        this.bookmarkService.findJapaneseHistoryBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findJapaneseHistoryBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findJapaneseHistoryBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findWorldHistoryBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findWorldHistoryBookmark),
      switchMap(action =>
        this.bookmarkService.findWorldHistoryBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findWorldHistoryBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findWorldHistoryBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findGeographyBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findGeographyBookmark),
      switchMap(action =>
        this.bookmarkService.findGeographyBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findGeographyBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findGeographyBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findPoliticalEconomyBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findPoliticalEconomyBookmark),
      switchMap(action =>
        this.bookmarkService.findPoliticalEconomyBookmarkProblems(action.condition).pipe(
          map(problems => BookmarkActions.findPoliticalEconomyBookmarkSuccess({ problems })),
          catchError(e =>
            of(
              BookmarkActions.findPoliticalEconomyBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );
  findBookmarkProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookmarkActions.findBookmarkProblemIds),
      switchMap(action =>
        this.bookmarkService.findBookmarkProblemIds(action.condition).pipe(
          map(bookmarkProblemIds => BookmarkActions.findBookmarkProblemIdsSuccess({ bookmarkProblemIds })),
          catchError(e =>
            of(
              BookmarkActions.findBookmarkProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private bookmarkService: BookmarkService) {}
}
