import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DOCUMENT, ViewportScroller } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { CommonIdGetStripeCheckoutUrlRequest } from 'src/app/models/common-id/common-id-get-stripe-checkout-url-request';
import { RootState } from 'src/app/reducers';
import { enter } from 'src/app/resources/animations';
import { GA_EVENT_ACTIONS } from 'src/app/resources/common-id/ga';
import { COMMON_ID_PURCHASE_DIALOG_WIDTH } from 'src/app/resources/config';
import { GAUtil } from 'src/app/utils/ga-util';
import { findCommonIdSignedInUser } from '../../../../actions/common-id/common-id-auth.actions';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { getCurrentDateTime } from '../../../../actions/current-date-time.actions';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { CurrentDateTime } from '../../../../models/current-date-time';
import { CONTACT_GOOGLE_FORM_URL } from '../../../../resources/common-id-config';
import { COMMON_ID_FAQS, CommonIdFaq } from '../../../../resources/common-id/faqs';
import { getCommonIdSignedInUser } from '../../../../selectors/common-id/common-id-auth.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../../selectors/current-date-time.selectors';
import { CommonIdContactUtil } from '../../../../utils/common-id/common-id-contact-util';
import { CommonIdUserUtil } from '../../../../utils/common-id/common-id-user-util';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { CommonIdPurchaseDialogComponent } from '../purchase-dialog/purchase-dialog.component';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

@Component({
  selector: 'app-common-id-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
  animations: [enter]
})
export class CommonIdPurchaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'プラン購入';
  private fragmentSubscription: Subscription;

  loaded$: Observable<boolean>;
  currentDateTime$: Observable<CurrentDateTime>;
  signedInUser$: Observable<CommonIdUser>;
  isPremiumUser$: Observable<boolean>;

  faqs: CommonIdFaq[];
  category1Faqs: CommonIdFaq[];
  category2Faqs: CommonIdFaq[];
  category3Faqs: CommonIdFaq[];

  constructor(
    protected store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private dialog: MatDialog,
    @Inject(WINDOW_OBJECT) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.loaded$ = of(true);

    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }

  setUp() {
    // 最新のユーザ情報を取得
    this.store.dispatch(findCommonIdSignedInUser());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.store.dispatch(getCurrentDateTime());

    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      shareReplay(1)
    );

    this.isPremiumUser$ = combineLatest([this.currentDateTime$, this.signedInUser$]).pipe(
      filter(it => it != null),
      map(([currentDateTime, signedInUser]) => CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser)),
      shareReplay(1)
    );

    this.faqs = JSON.parse(JSON.stringify(COMMON_ID_FAQS.filter(faq => faq.planFlag)));
    this.faqs.forEach((faq, index) => {
      this.faqs[index].body = faq.body.replace(/data-href="#faq-(\d+)"/g, 'href="/c/purchase#faq-$1"');
    });
    this.signedInUser$.pipe(take(1)).subscribe(user => {
      this.faqs.forEach((faq, index) => {
        const url = CommonIdContactUtil.getGoogleFormUrl(CONTACT_GOOGLE_FORM_URL, user.commonId, this.window.navigator.userAgent);
        this.faqs[index].body = faq.body.replace('<contact-url>', url);
      });
    });
    this.category1Faqs = this.faqs.filter(faq => faq.categoryId === 1);
    this.category2Faqs = this.faqs.filter(faq => faq.categoryId === 2);
    this.category3Faqs = this.faqs.filter(faq => faq.categoryId === 3);

    this.fragmentSubscription = this.activatedRoute.fragment.subscribe(fragment => {
      const targetFaq = this.faqs.find(faq => faq.id === fragment);
      if (targetFaq) {
        targetFaq.isExpanded = true;
        setTimeout(() => this.viewportScroller.scrollToAnchor(fragment), 300);
      }
    });
  }

  getStripeCheckoutUrl(planId: number) {
    const eventCategory = GAUtil.getGAEventCategoryPlan(planId);
    const eventLabel = localStorage.getItem('previousUrl');
    const eventParams = {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    const data: CommonIdGetStripeCheckoutUrlRequest = {
      planId,
      isLocal: /^http:\/\/localhost:4200/.test(location.href)
    };
    const config: MatDialogConfig = {
      width: COMMON_ID_PURCHASE_DIALOG_WIDTH,
      autoFocus: true,
      data,
      disableClose: true
    };
    this.dialog.open(CommonIdPurchaseDialogComponent, config).afterClosed();
  }

  gotoStripeClick(event) {
    this.getStripeCheckoutUrl(event.planId);
  }

  gotoSearchUniv() {
    const url = RoutingPathResolver.resolveCommonIdSearchUniv();
    this.store.dispatch(navigate({ url }));
  }

  gotoFaq(id: string) {
    const targetFaq = this.faqs.find(faq => faq.id === id);
    if (targetFaq) {
      targetFaq.isExpanded = true;
      setTimeout(() => this.viewportScroller.scrollToAnchor(id), 300);
    }
  }

  gotoFaqClick(event) {
    this.gotoFaq(event.faqId);
  }
}
