<div class="container" *ngIf="(loaded$ | async) === true">
  <div *ngIf="(isError$ | async) !== true; else noImageData">
    <div class="question-image-container" *ngIf="(paramType$ | async) === 'q'">
      <ng-container *ngFor="let image of questionImages$ | async">
        <img [src]="image" class="image-container" />
        <!--クリックの処理は不要なのでコメントアウト  -->
        <!--img [src]="image" (click)="clickImage(image)" class="image-container" /-->
      </ng-container>
    </div>

    <div class="answer-image-container" *ngIf="(paramType$ | async) === 'a'">
      <ng-container *ngFor="let image of answerImages$ | async">
        <img [src]="image" class="image-container" />
        <!--クリックの処理は不要なのでコメントアウト  -->
        <!--img [src]="image" (click)="clickImage(image)" class="image-container" /-->
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noImageData>
  <div class="error-messages">
    <p>
      指定された過去問は見つかりませんでした
    </p>
  </div>
</ng-template>
