import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PURCHASE_PLAN_NAMES, PurchasePlan } from '../../../resources/common-id-config';

@Component({
  selector: 'app-content-lp-pricing-sections',
  templateUrl: './content-lp-pricing-sections.component.html',
  styleUrls: ['./content-lp-pricing-sections.component.scss']
})
export class ContentLpPricingSectionsComponent implements OnInit {
  @Input() isPremiumUser: boolean;
  @Input() parentComponent: string;
  @Output() gotoStripeClick = new EventEmitter<{ planId: string }>();

  buttonIsShow: boolean;
  buttonDisabled: boolean;

  annualPlan = {
    id: PurchasePlan.ANNUAL,
    name: PURCHASE_PLAN_NAMES.find(it => it.id === PurchasePlan.ANNUAL).name
  };
  biannualPlan = {
    id: PurchasePlan.BIANNUAL,
    name: PURCHASE_PLAN_NAMES.find(it => it.id === PurchasePlan.BIANNUAL).name
  };
  shortTerm3MonthsPlan = {
    id: PurchasePlan.SHORT_TERM_3MONTHS,
    name: PURCHASE_PLAN_NAMES.find(it => it.id === PurchasePlan.SHORT_TERM_3MONTHS).name
  };

  constructor() {}
  ngOnInit() {
    if (this.parentComponent === 'top') {
      this.buttonIsShow = false;
    } else {
      this.buttonIsShow = true;
    }

    this.buttonDisabled = this.isPremiumUser;
  }
}
